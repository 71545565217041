<template>
  <div class="municipality">
    <v-card class="table-dashboard">
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :footer-props="{
          'items-per-page-text': 'Registros por Página',
        }"
        :page="page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :server-items-length="TotalResgister"
        class="elevation-1"
      >
        <template v-slot:item.ambito="{ item }">
          <div class="table-center">
            <v-tooltip bottom color="#24989D">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-show="Number(item.delegate.has_deficiency)"
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-wheelchair
                </v-icon>
              </template>
              <span>Deficiente</span>
            </v-tooltip>
            <v-tooltip bottom color="#24989D">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-show="Number(item.delegate.has_lactating)"
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="mr-2"
                >
                  mdi-baby-bottle
                </v-icon>
              </template>
              <span>Amamentando</span>
            </v-tooltip>
            <v-tooltip bottom color="#24989D">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-show="Number(item.delegate.companion.length)"
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="mr-2"
                >
                  mdi-account-multiple
                </v-icon>
              </template>
              <span>Acompanhante</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <div class="header-table">
                  <ButtonEntity v-show="permissionLvl2" />
                  <div class="header-space-table">
                    <DialogTableDelegate
                      :origin="origin"
                      :resource="resource"
                      v-bind="attrs"
                      v-on="on"
                      v-on:eventname="getDelegete()"
                      v-show="permissionLvl2"
                    />
                    <v-btn color="green" dark @click="getDelegete()">
                      atualizar
                    </v-btn>
                  </div>
                </div>
              </template>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Tem certeza que deseja deletar este item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >cancelar</v-btn
                  >
                  <v-btn color="red darken-1" text @click="deleteItemConfirm"
                    >Deletar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template
          v-slot:item.actions="{ item }"
          v-if="
            $store.state.profile_id != 2 &&
            $store.state.profile_id != 21 &&
            $store.state.profile_id != 41
          "
        >
          <v-icon small class="mr-2" @click="editItem(item.delegate, item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogEdit" max-width="880">
      <AlertError2
        class="mb-5"
        v-model="displayError2"
        :resource="displayError2"
      />

      <v-card>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Cadastro
            </v-stepper-step>
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 2" step="2">
              Necessidades Especiais
            </v-stepper-step>
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 3" step="3">
              Endereço
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12 px-5 py-5" height="480px">
                <v-form ref="form" lazy-validation class="tab-1-dialog">
                  <v-text-field
                    label="Nome"
                    :rules="nameRules"
                    :counter="60"
                    v-model="name"
                    id="name"
                    placeholder="Nome*"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Nome Social"
                    v-model="socialName"
                    :counter="60"
                    id="social"
                    placeholder="Nome Social"
                    outlined
                    dense
                    class="input-ajust grid-col-segund"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="CPF"
                    masked="false"
                    v-mask.raw="'###.###.###-##'"
                    :rules="cpfRules"
                    v-model="cpfCommission"
                    :disabled="true"
                    id="CPF"
                    placeholder="CPF*"
                    outlined
                    dense
                    class="input-ajust crash"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Data de nascimento"
                    v-model="birthDate"
                    type="date"
                    id="date"
                    placeholder="Data de Nascimento"
                    outlined
                    dense
                    class="input-ajust columns-5-3"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="E-mail*"
                    :counter="70"
                    :rules="emailRules"
                    v-model="email"
                    id="email"
                    type="email"
                    placeholder="E-mail"
                    outlined
                    dense
                    class="input-ajust crash"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Telefone"
                    :rules="mandatory"
                    v-mask="['(##) ####-#####']"
                    v-model="tell"
                    id="date"
                    placeholder="Telefone"
                    outlined
                    dense
                    class="input-ajust columns-5-3"
                    required
                  ></v-text-field>
                  <v-select
                    :items="resource.getAllGender"
                    item-text="name"
                    item-value="id"
                    id="sex"
                    :rules="mandatory"
                    v-model="genre"
                    label="Gênero"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    label="Modalidade"
                    :rules="mandatory"
                    item-text="name"
                    item-value="id"
                    :items="modalitys"
                    v-model="modality"
                    placeholder="Modalidade"
                    outlined
                    dense
                    required
                  ></v-select>
                  <v-select
                    :items="ownerships"
                    class="input-ajust columns-5-3"
                    v-model="ownership"
                    :rules="mandatory"
                    item-text="name"
                    item-value="id"
                    label="Titularidade*"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    :items="eixos"
                    v-model="eixo"
                    :rules="mandatory"
                    item-text="title"
                    item-value="id"
                    label="Eixo*"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    :items="segments"
                    class="t"
                    v-model="segment"
                    :rules="mandatory"
                    item-text="name"
                    item-value="id"
                    label="Segmento*"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    :items="resource.getAllBreed"
                    :rules="mandatory"
                    item-text="name"
                    item-value="id"
                    id="breed"
                    v-model="breed"
                    label="Raça/Cor"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    style="max-width: 200px"
                    :items="resource.getAllEntity"
                    item-text="name"
                    :rules="mandatory"
                    item-value="id"
                    id="Ôrgão/Entidade"
                    v-model="issuingAgency"
                    label="Ôrgão/Entidade"
                    required
                    dense
                    outlined
                  ></v-select>

                  <v-text-field
                    label="RG"
                    id="tellResi"
                    :rules="mandatory"
                    v-model="rg"
                    v-mask="['##.###.###-##']"
                    placeholder="RG"
                    outlined
                    dense
                    class="input-ajust r"
                    required
                  ></v-text-field>

                  <v-text-field
                    label="Orgão/Emissor"
                    :counter="4"
                    :rules="mandatory"
                    id="tellResi"
                    v-model="issuer"
                    max="4"
                    placeholder="Ex: SSP"
                    outlined
                    dense
                    class="input-ajust r"
                    required
                  ></v-text-field>

                  <v-select
                    :items="sexualitys"
                    item-text="name"
                    :rules="mandatory"
                    item-value="id"
                    v-model="sexuality"
                    label="Sexualidade"
                    dense
                    outlined
                  ></v-select>
                </v-form>
              </v-card>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialogEdit = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="nexTab()"> Continuar </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card class="mb-12 px-5 py-5" height="300px">
                <v-form ref="form2" lazy-validation class="tab-2-dialog">
                  <v-checkbox
                    v-model="checkedDeficiency"
                    label="Possui Deficiência?"
                  >
                  </v-checkbox>

                  <v-select
                    v-if="checkedDeficiency"
                    :rules="mandatory"
                    :items="resource.getAllDeficiency"
                    item-text="name"
                    item-value="id"
                    v-model="selectDeficiency"
                    label="Selecione"
                    outlined
                    multiple
                  ></v-select>

                  <v-checkbox
                    v-model="breastfeeding"
                    label="Estará Amamentando no período da Conferência?"
                  >
                  </v-checkbox>

                  <v-checkbox
                    v-model="escortActive"
                    label="Necessidade de Acompanhante?"
                  >
                  </v-checkbox>
                  <v-text-field
                    v-if="escortActive"
                    label="Nome"
                    :rules="nameRules"
                    :counter="30"
                    v-model="nameEscort"
                    id="name"
                    placeholder="Nome*"
                    outlined
                    dense
                    class="input-ajust"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-if="escortActive"
                    label="Nome Social"
                    v-model="nameSocialEscort"
                    :counter="30"
                    id="social"
                    placeholder="Nome Social"
                    outlined
                    dense
                    class="input-ajust"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-if="escortActive"
                    label="CPF"
                    masked="false"
                    v-mask.raw="'###.###.###-##'"
                    :rules="cpfRules"
                    v-model="cpfEscort"
                    id="CPF"
                    placeholder="CPF*"
                    outlined
                    dense
                    class="input-ajust"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-if="escortActive"
                    label="RG"
                    id="tellResi"
                    :rules="mandatory"
                    v-model="rgEscort"
                    v-mask="['##.###.###-##']"
                    placeholder="RG"
                    outlined
                    dense
                    required
                  ></v-text-field>

                  <v-select
                    v-if="escortActive"
                    :items="resource.getAllGender"
                    item-text="name"
                    item-value="id"
                    id="sex"
                    :rules="mandatory"
                    v-model="genreEscort"
                    label="Gênero"
                    required
                    dense
                    outlined
                  ></v-select>
                </v-form>
              </v-card>
              <v-snackbar v-model="snackbar">
                {{ message2 }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="green"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialogCancel = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="e1 = 1"> Voltar </v-btn>
                <v-btn color="primary" @click="nexTabTwo()"> continuar </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-card class="mb-12 px-5 py-5" height="300px">
                <v-form ref="form3" lazy-validation class="tab-1-dialog">
                  <v-text-field
                    label="Logradouro"
                    type="text"
                    :rules="nameLogradouro"
                    :counter="60"
                    placeholder="Logradouro"
                    v-model="publicPlace"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>

                  <v-text-field
                    label="CEP"
                    id="cep"
                    :rules="mandatory"
                    placeholder="CEP"
                    v-model="cep"
                    v-mask="'#####-###'"
                    outlined
                    dense
                    class="input-ajust grid-col-segund"
                    required
                  ></v-text-field>

                  <v-text-field
                    label="BAIRRO"
                    v-model="district"
                    placeholder="BAIRRO"
                    :rules="namesTrick"
                    :counter="40"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>

                  <v-select
                    :items="resource.getAllCounty"
                    item-text="name"
                    item-value="id"
                    v-model="municipaly"
                    :rules="mandatory"
                    class="input-ajust grid-col-segund"
                    label="Municipio"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-text-field
                    label="NÚMERO"
                    v-model="numberStreet"
                    type="number"
                    id="numberStreet"
                    :counter="3"
                    min="0"
                    placeholder="Número Residência"
                    :rules="numberResidencie"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>
                </v-form>
              </v-card>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialogCancel = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="e1 = 2"> Voltar </v-btn>
                <v-btn color="primary" @click="submitForm()"> Finalizar </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import DialogTableDelegate from "../dialogs/dialog-table-delegate.vue";
import AlertError2 from "../alerts/Error2-component.vue";
import ButtonEntity from "../dialogEdit/dialog-launch-all.vue";

export default {
  data: () => ({
    dialog: false,
    dialogCancel: false,
    dialogEdit: false,
    snackbar: false,
    dialogDelete: false,
    loading: true,
    items: [],
    sortBy: null,
    TotalResgister: null,

    //Editar
    e1: 1,
    message: [],
    message2: [],

    displayError2: {
      display2: false,
      message2: [],
    },

    //Cadastro
    breastfeeding: false,
    escortActive: false,
    sexualitys: [],

    //ID de registro
    id_companion: null,
    id_delegate: null,
    id_address: null,
    id_delete: null,
    id_email: null,
    id_phone: null,
    id_axie: null,
    id_cpf: null,
    id_rg: null,

    name: null,
    socialName: null,
    eixos: [],
    eixo: null,
    modality: null,
    ownership: null,
    ownerships: [],
    modalitys: [],
    cpfCommission: "",
    segment: null,
    segments: [],
    birthDate: null,
    genre: null,
    breed: null,
    issuingAgency: null,
    rg: null,
    checkedDeficiency: false,
    selectDeficiency: [],
    tell: "",
    issuer: "",
    email: null,
    nameEscort: null,
    rgEscort: null,
    cpfEscort: null,
    nameSocialEscort: null,
    genderEscort: null,
    genreEscort: null,

    // Endereço
    publicPlace: null,
    cep: null,
    numberStreet: null,
    municipaly: null,
    district: null,

    page: 1,
    numberOfPages: 0,
    passengers: [],
    options: {},
    resultQuery: null,
    people_conference_id: null,
    //Estrutura
    formData: {
      people: {
        name: null,
        gender_id: null,
        modality_id: null,
        birth_date: null,
        social_name: null,
        agency_id: null,
        sexuality_id: null,
        breed_id: null,
        conference_id: null,
        ownership_id: null,
        has_lactating: null,
        has_deficiency: null,
        axies: [],
        companion: [],
        deficiency: [],
      },
      address: {
        cep: null,
        county_id: null,
        public_place: null,
        address_number: null,
        district: null,
      },

      phone: [],
      documents: {
        RG: {
          document_number: null,
          issuing_body: null,
        },
        CPF: {
          document_number: null,
        },
      },
      email: {
        name: null,
      },
    },

    permissionLvl2: false,

    // Regras de validação
    mandatory: [(v) => !!v || "Campo obrigatório!"],

    cpfRules: [
      (v) => !!v || "O CPF é obrigatório",
      (v) => (v && v.length <= 14) || "Você excedeu o tamanho máximo.",
    ],

    nameRules: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 60) || "Você excedeu o tamanho máximo.",
    ],

    numberResidencie: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 4) || "Você excedeu o tamanho máximo!",
      (v) => (v && v > 0) || "O número é abaixo do esperado",
    ],

    nameLogradouro: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
    ],

    namesTrick: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
    ],

    emailRules: [
      (v) => !!v || "O campo é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "O e-mail não é válido.",
      (v) => (v && v.length <= 70) || "Você excedeu o tamanho máximo.",
    ],

    headers: [
      { text: "AÇÕES", value: "actions", sortable: false },
      {
        text: "Nome",
        align: "start",
        sortable: false,
        value: "delegate.name",
      },
      { text: "Município", value: "county.name" },
      { text: "Raça/Cor", value: "delegate.breed.name" },
      { text: "Sexo", value: "delegate.gender.name" },
      { text: "Titularidade", value: "ownership.name" },
      { text: "Eixo", value: "axie.title" },
      { text: "Segmento", value: "segment.name" },
      { text: "Necessidades", value: "ambito" },
    ],
  }),

  components: { DialogTableDelegate, AlertError2, ButtonEntity },

  props: {
    resource: {
      type: Object,
    },
    origin: {
      type: String,
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    sortBy: {
      handler() {
        console.log(this.sortBy);
      },
    },
    options: {
      handler() {
        this.getDelegete();
      },
    },
    deep: true,
  },

  mounted() {
    this.getDelegete();
  },

  created() {
    this.verification();
  },

  methods: {
    getDelegete() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;
      let data = new Object();
      data.per_page = this.options.itemsPerPage;

      if (pageNumber == undefined) {
        pageNumber = 1;
      }
      if (data.per_page == undefined) {
        data.per_page = 10;
      }

      if (this.origin == "institute") {
        this.resultQuery = `conference/${this.$route.query.id}/delegates?page=${pageNumber}&per_page=${data.per_page}&institute_id=${this.$route.query.institute_id}`;
      }

      if (this.origin == "county") {
        this.resultQuery = `conference/${this.$route.query.id}/delegates?page=${pageNumber}&per_page=${data.per_page}&county_id=${this.$route.query.county_id}`;
      }

      if (this.origin == "regional_nucleus") {
        this.resultQuery = `conference/${this.$route.query.id}/delegates?page=${pageNumber}&per_page=${data.per_page}&regional_nucleus_health_id=${this.$route.query.regional_nucleus_health_id}`;
      }

      if (this.origin == "state") {
        this.resultQuery = `conference/${this.$route.query.id}/delegates?page=${pageNumber}&per_page=${data.per_page}&state_id=${this.$route.query.state_id}`;
      }

      axios.get(this.resultQuery).then((res) => {
        this.loading = false;
        this.items = res.data.data;

        this.TotalResgister = res.data.total;
        this.numberOfPages = res.data.last_page;
      });
    },

    getFunction() {
      axios.get("segment").then((res) => {
        this.segments = res.data;
      });
      axios.get("modality").then((res) => {
        this.modalitys = res.data;
      });
      axios.get("ownership").then((res) => {
        this.ownerships = res.data;
      });

      axios.get(`sexuality`).then((res) => {
        this.sexualitys = res.data;
      });
    },

    loadSelects() {
      axios.get(`/county`).then((response) => {
        this.countyListOrgao = response.data;
      });

      axios.get(`/country`).then((response) => {
        this.countryListOrgao = response.data;
      });

      axios.get(`/state`).then((response) => {
        this.stateListOrgao = response.data;
      });

      axios.get(`conference/${this.$route.query.id}/axie`).then((res) => {
        this.eixos = res.data;
      });
    },

    validate() {
      this.$refs.form.validate();
    },

    nexTab() {
      if (!this.$refs.form.validate()) return;
      this.e1 = 2;
    },

    nexTabTwo() {
      if (!this.$refs.form2.validate()) return;
      this.e1 = 3;
    },

    resetForm() {
      this.$refs.form3.reset();
      this.$refs.form3.resetValidation();
    },

    editItem(item, people_conference) {
      this.loadSelects();
      this.getFunction();
      let cpf;
      let rg;
      cpf = this.getRgAndCpf(item.documents).cpf[0];
      rg = this.getRgAndCpf(item.documents).rg[0];

      if (rg) {
        this.id_rg = rg.id;
        this.rg = rg.document_number;
        this.issuer = rg.issuing_body;
      }
      //ID de registro
      this.name = item.name;
      this.people_conference_id = people_conference.id;
      this.id_companion = item.companion.length ? item.companion[0].id : null;
      this.id_email = item.email[0].id;
      this.id_phone = item.phone[0].id;
      this.id_axie = Number(people_conference.axie_id);
      this.id_delegate = item.id;

      this.id_cpf = cpf.id;
      this.cpfCommission = cpf.document_number;

      //Registro
      this.sexuality = Number(item.sexuality_id);
      this.socialName = item.social_name;
      this.eixo = Number(people_conference.axie_id);
      this.modality = Number(people_conference.modality_id);
      this.ownership = Number(people_conference.ownership_id);

      this.segment = Number(people_conference.segment_id);
      this.birthDate =
        item.birth_date == null ? "" : item.birth_date.slice(0, 10);
      this.genre = Number(item.gender_id);
      this.breed = Number(item.breed_id);
      this.issuingAgency = Number(item.agency_id);

      this.tell = item.phone[0].ddd + item.phone[0].phone_number;

      this.email = item.email[0].name;

      //Acompanhante

      if (item.companion.length > 0) {
        this.escortActive = 1;
      } else {
        this.escortActive = 0;
      }

      this.nameEscort = item.companion.length ? item.companion[0].name : null;
      this.rgEscort = item.companion.length ? item.companion[0].rg : null;
      this.cpfEscort = item.companion.length ? item.companion[0].cpf : null;
      this.nameSocialEscort = item.companion.length
        ? item.companion[0].social_name
        : null;
      this.genreEscort = item.companion.length
        ? Number(item.companion[0].gender_id)
        : null;
      this.breastfeeding = Number(item.has_lactating);
      if (item.deficiency.length > 0) {
        this.checkedDeficiency = 1;
      } else {
        this.checkedDeficiency = 0;
      }
      this.selectDeficiency = [];
      for (let i = 0; i < item.deficiency.length; i++) {
        this.selectDeficiency.push(item.deficiency[i].id);
      }

      // Endereço
      if (item.address.length > 0) {
        this.id_address = item.address[0].id;
        this.publicPlace = item.address[0].public_place;
        this.cep = item.address[0].cep;
        this.numberStreet = item.address[0].address_number;
        this.municipaly = Number(item.address[0].county_id);
        this.district = item.address[0].district;
      }

      this.dialogEdit = true;
    },

    getRgAndCpf(documents) {
      let rg;
      let cpf;
      cpf = documents.filter((x) => x.document_type_id == "1");
      rg = documents.filter((x) => x.document_type_id == "2");
      return { rg: rg, cpf: cpf };
    },

    submitForm() {
      if (!this.$refs.form.validate()) return;
      if (!this.$refs.form2.validate()) return;
      if (!this.$refs.form3.validate()) return;
      function removeFirstTwoDigits(phoneNumber, id_number) {
        let ddd = phoneNumber.substring(0, 2);
        phoneNumber = phoneNumber.substring(2);
        return [{ ddd: ddd, phone_number: phoneNumber, id: id_number }];
      }

      //REGEX
      const newCPF = this.cpfCommission.replace(/[^a-zA-Z0-9]/g, "");
      const newRG = this.rg.replace(/[^a-zA-Z0-9]/g, "");
      const newPhone = this.tell.replace(/[^\d]/g, "");
      const newCEP = this.cep.replace(/[^a-zA-Z0-9]/g, "");

      let newCPFCompania = null;
      let newRGCompania = null;

      if (this.cpfEscort != null) {
        newCPFCompania = this.cpfEscort.replace(/[^a-zA-Z0-9]/g, "");
      }

      if (this.rgEscort != null) {
        newRGCompania = this.rgEscort.replace(/[^a-zA-Z0-9]/g, "");
      }

      const companion_ = [
        {
          social_name: this.nameSocialEscort,
          id: this.id_companion,
          name: this.nameEscort,
          cpf: newCPFCompania,
          rg: newRGCompania,
          gender_id: this.genreEscort,
        },
      ];

      const axies_ = [this.eixo];

      this.formData.people.people_conference_id = this.people_conference_id;
      // First stap
      this.formData.people.conference_id = this.$route.query.id;
      this.formData.people.social_name = this.socialName;
      this.formData.people.name = this.name;
      this.formData.documents.CPF.document_number = newCPF;
      this.formData.documents.CPF.id = this.id_cpf;
      this.formData.people.birth_date = this.birthDate;
      this.formData.email.name = this.email;
      this.formData.email.id = this.id_email;
      this.formData.phone = removeFirstTwoDigits(newPhone, this.id_phone);
      this.formData.people.sexuality_id = this.sexuality;

      this.formData.people.gender_id = this.genre;
      this.formData.people.modality_id = this.modality;
      this.formData.people.ownership_id = this.ownership;
      this.formData.people.segment_id = this.segment;
      this.formData.documents.RG.issuing_body = this.issuer;
      this.formData.documents.RG.document_number = newRG;
      this.formData.documents.RG.id = this.id_rg;
      this.formData.people.agency_id = this.issuingAgency;
      this.formData.people.breed_id = this.breed;

      this.formData.people.axies = axies_;
      // End First stap

      // Start Second Stap
      if (this.selectDeficiency.length >= 1) {
        this.formData.people.deficiency = this.selectDeficiency;
      }

      this.formData.people.escortActive = this.escortActive;
      this.formData.people.has_deficiency = this.checkedDeficiency;
      this.formData.people.has_lactating = this.breastfeeding;

      this.formData.people.companion = companion_;
      // End Second Stap

      // Start Endereço
      this.formData.address.cep = newCEP;
      this.formData.address.public_place = this.publicPlace;
      this.formData.address.address_number = this.numberStreet;
      this.formData.address.county_id = this.municipaly;
      this.formData.address.district = this.district;
      this.formData.address.id = this.id_address;
      // End Endereço

      if (this.origin == "state") {
        this.formData.people.state_id = this.$route.query.state_id;
      }
      if (this.origin == "regional_nucleus") {
        this.formData.people.regional_nucleus_health_id =
          this.$route.query.regional_nucleus_health_id;
      }
      if (this.origin == "institute") {
        this.formData.people.institute_id = this.$route.query.institute_id;
      }
      if (this.origin == "county") {
        this.formData.people.county_id = this.$route.query.county_id;
      }

      // console.log("Date: ", JSON.parse(JSON.stringify(this.formData)));
      axios
        .put(`people/${this.id_delegate}/delegates`, this.formData)
        .then((res) => {
          this.getDelegete();
          this.snackbar = true;
          this.message = res.data.message;
          this.$refs.form3.reset();
          this.$refs.form2.reset();
          this.$refs.form.reset();
          setTimeout(() => {
            this.e1 = 1;
            this.dialogEdit = false;
          }, 2000);
        })
        .catch((err) => {
          setTimeout(() => {
            this.displayError2.display2 = false;
            this.disabledSubmit = false;
          }, 5000);

          if (typeof err === "string") {
            this.displayError2.message2 = err;
            this.displayError2.display2 = true;
          }

          if (typeof err !== "string") {
            if (err.data.message) {
              this.$swal({
                icon: "error",
                title: "Erro ao atualizar delegados",
                text: err.data.message,
              });
            }

            if (err.data.details) {
              this.$swal({
                icon: "error",
                title: "Erro ao atualizar delegados",
                text: err.data.details,
              });
            }
          }
          this.formData.people = {};
        });
    },

    deleteItem(item) {
      this.dialogDelete = true;
      this.id_delete = item.id;
    },
    deleteItemConfirm() {
      axios
        .delete(`people-conference/${this.id_delete}`)
        .then((res) => {
          this.getDelegete();
          this.snackbar = true;
          this.message = res.data.message;
          this.dialogDelete = false;
        })
        .catch((err) => {
          err;
          var error = "";
          Object.values(this.$store.state.errorMessage).map((value) => {
            error = value;
          });
          this.displayError2.message2 = error.toString();
          this.snackbar = true;
          this.message = error.toString();
          this.displayError2.display2 = true;
        });
    },

    verification() {
      if (
        Number(this.$store.state.finished_release_conference) &&
        Number(this.$store.state.restrict_release_conference)
      ) {
        this.permissionLvl2 =
          Number(this.$store.state.profile_id) == 1 ? true : false;
      } else {
        this.permissionLvl2 = true;
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style scoped>
.delegate-button {
  margin-right: 30px;
  margin-left: 20px;
  display: flex;
  width: 650px;
  justify-content: center;
  align-items: center;
}

.grid-col-ajust {
  grid-column: 1 / span 2 !important;
}

.r {
  grid-row: 5;
}

.grid-col-segund {
  grid-column: 3 / span 2;
}

.bnt-bottom-conf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tab-1-dialog {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 194px 210px 175px 185px;
  gap: 8px;
}

.tab-2-dialog {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 194px 210px 175px;
  gap: 8px;
}

.crash {
  grid-column: 1 / 3;
}

.columns-5-3 {
  grid-column: 5 / 3;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.header-table {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-center {
  display: flex;
  align-items: center;
}

.header-space-table {
  display: flex;
  justify-self: center;
  align-items: center;
  gap: 20px;
  margin: 0 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  background: -webkit-linear-gradient(to right, #27ae60, #1abc9c);
  background: linear-gradient(to right, #27ae60, #1abc9c);

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.row + .row {
  margin: 0px !important;
}

.cardFooter {
  padding: 0 !important;
}
</style>