<template>
  <v-row justify="center">
    <v-btn
      class="ml-2"
      color="secondary"
      v-if="
        ($store.state.profile_id == 1 || $store.state.profile_id == 8) &&
        $route.query.state_id
      "
      dark
      @click.stop="openModalImportArchive()"
    >
      IMPORTAR DELEGADOS
    </v-btn>
    <v-btn
      class="ml-2"
      color="primary"
      v-show="!verifyIfConferenceIsEnded()"
      v-if="
        $store.state.profile_id != 2 &&
        $store.state.profile_id != 21 &&
        $store.state.profile_id != 41
      "
      dark
      @click.stop="openModal()"
    >
      CADASTRAR DELEGADOS
    </v-btn>

    <v-dialog v-model="dialogImporResponse" persistent max-width="700px">
      <v-card class="card_response">
        <v-row justify="center" class="mb-3 mt-3">
          <v-card-title
            style="
              display: flex;
              justify-content: center;
              background-color: #424242;
              color: #ffffff;
            "
          >
            <span class="text-h5">RESULTADO DA IMPORTAÇÃO</span>
            <h3>{{ dialogImportResponseMessage }}</h3>
          </v-card-title>
          <v-card-title
            class="mt-2"
            style="display: flex; justify-content: center; color: #424242"
          >
            <v-btn
              color="#C62828"
              style="color: white"
              variant="text"
              @click="dialogImporResponse = false"
            >
              FECHAR
            </v-btn>
          </v-card-title>
        </v-row>
        <v-card-text>
          <v-container>
            <v-row justify="center">
              <v-col cols="12" sm="6" md="4">
                <h4>CPF's cadastrados ({{ itsRegisterable.length }})</h4>
                <v-list-item
                  v-for="(item, index) in itsRegisterable"
                  :key="item"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ index + 1 }}. {{ item.CPF }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <h4>
                  CPF's já registrados na fase estadual ({{
                    itsAlreadyRegistered.length
                  }})
                </h4>
                <v-list-item
                  v-for="(item, index) in itsAlreadyRegistered"
                  :key="item.id"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ index + 1 }}. {{ item.CPF }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <h4>CPF's não registrados ({{ itsNotRegisterable.length }})</h4>
                <v-list-item
                  v-for="(item, index) in itsNotRegisterable"
                  :key="item.id"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ index + 1 }}. {{ item.CPF }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogImportArchive" persistent max-width="700px">
      <AlertError2
        class="mb-5"
        v-model="displayError2"
        :resource="displayError2"
      />
      <v-card class="card_response">
        <v-row justify="center" class="mb-3 mt-3">
          <v-card-title>
            <span class="text-h5">IMPORTAÇÃO DE DELEGADOS</span>
          </v-card-title>
        </v-row>
        <v-card-text>
          <v-container>
            <v-row justify="center">
              <v-col cols="12" sm="6" md="6">
                <v-file-input
                  @change="onFileChange"
                  v-model="archiveDelegateImport"
                  color="deep-purple accent-4"
                  counter
                  label="Adicione seu arquivo"
                  multiple
                  placeholder="Adicione seu arquivo"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                      v-if="index < 2"
                      color="deep-purple accent-4"
                      dark
                      label
                      small
                    >
                      {{ text }}
                    </v-chip>

                    <span
                      v-else-if="index === 2"
                      class="text-overline grey--text text--darken-3 mx-2"
                    >
                      +{{ files.length - 2 }} File(s)
                    </span>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row justify="center">
              <v-col cols="12" md="12">
                <p style="color: red; justify-text: left; font-size: 12pt">
                  Os registros que contiverem informações como
                  (<strong>CPF</strong>, <strong>Representação</strong>,
                  <strong>Titularidade</strong> e <strong>Eixo</strong>)
                  incorretas ou não existirem no banco de dados do sistema, não
                  serão importados.
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#C62828"
            style="color: white"
            variant="text"
            @click="dialogImportArchive = false"
          >
            FECHAR
          </v-btn>
          <v-btn
            color="primary"
            variant="text"
            @click="importArchive()"
            :disabled="disableSubmitImportArchiveDelegates"
          >
            IMPORTAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="880">
      <AlertError2
        class="mb-5"
        v-model="displayError2"
        :resource="displayError2"
      />

      <v-card>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Cadastro
            </v-stepper-step>
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 2" step="2">
              Necessidades Especiais
            </v-stepper-step>
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 3" step="3">
              Endereço
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row>
                <v-col cols="6" class="px-5 py-5">
                  <v-text-field
                    label="Pesquisar delegado por CPF"
                    v-model="delegateCPF"
                    v-mask.raw="'###.###.###-##'"
                    placeholder="Pesquisar delegado por CPF"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    class="mt-2"
                    color="primary"
                    @click="searchDelegateByCpf()"
                  >
                    Pesquisar
                  </v-btn>
                  <v-btn
                    class="mt-2 ml-2"
                    color="#C62828"
                    style="color: white"
                    @click="resetForm()"
                  >
                    Limpar
                  </v-btn>
                </v-col>
              </v-row>

              <v-card class="mb-12 px-5 py-5" height="580px">
                <v-form ref="form" lazy-validation class="tab-1-dialog">
                  <v-text-field
                    label="Nome"
                    :rules="nameRules"
                    :counter="60"
                    v-model="name"
                    id="name"
                    placeholder="Nome*"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Nome Social"
                    v-model="socialName"
                    :counter="60"
                    id="social"
                    placeholder="Nome Social"
                    outlined
                    dense
                    class="input-ajust grid-col-segund"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="CPF"
                    masked="false"
                    :disabled="disabledCPF"
                    v-mask.raw="'###.###.###-##'"
                    :rules="cpfRules"
                    v-model="cpfCommission"
                    id="CPF"
                    placeholder="CPF*"
                    outlined
                    dense
                    class="input-ajust crash"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Data de nascimento"
                    v-model="birthDate"
                    type="date"
                    id="date"
                    placeholder="Data de Nascimento"
                    outlined
                    dense
                    class="input-ajust columns-5-3"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="E-mail*"
                    :counter="70"
                    :rules="emailRules"
                    v-model="email"
                    id="email"
                    type="email"
                    placeholder="E-mail"
                    outlined
                    dense
                    class="input-ajust crash"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Telefone"
                    :rules="mandatory"
                    v-mask="['(##) ####-#####']"
                    v-model="tell"
                    id="date"
                    placeholder="Telefone"
                    outlined
                    dense
                    class="input-ajust columns-5-3"
                    required
                  ></v-text-field>
                  <v-select
                    :items="genreAllc"
                    item-text="name"
                    item-value="id"
                    id="sex"
                    :rules="mandatory"
                    v-model="genre"
                    label="Gênero"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    label="Presencial"
                    item-text="name"
                    item-value="id"
                    :items="modalitys"
                    v-model="modality"
                    disabled
                    placeholder="Presencial"
                    outlined
                    dense
                    required
                  ></v-select>
                  <v-select
                    :items="ownerships"
                    class="input-ajust columns-5-3"
                    v-model="ownership"
                    :rules="mandatory"
                    item-text="name"
                    item-value="id"
                    label="Titularidade*"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    :items="eixos"
                    v-model="eixo"
                    :rules="mandatory"
                    item-text="title"
                    item-value="id"
                    label="Eixo*"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    :items="segments"
                    class="t"
                    v-model="segment"
                    :rules="mandatory"
                    item-text="name"
                    item-value="id"
                    label="Segmento*"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    :items="resource.getAllBreed"
                    :rules="mandatory"
                    item-text="name"
                    item-value="id"
                    id="breed"
                    v-model="breed"
                    label="Raça/Cor"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    style="max-width: 200px"
                    :items="resource.getAllEntity"
                    item-text="name"
                    :rules="mandatory"
                    item-value="id"
                    id="Ôrgão/Entidade"
                    v-model="issuingAgency"
                    label="Ôrgão/Entidade"
                    required
                    dense
                    outlined
                  ></v-select>

                  <v-text-field
                    label="RG"
                    id="tellResi"
                    :rules="mandatory"
                    v-model="rg"
                    v-mask="['##.###.###-##']"
                    placeholder="RG"
                    outlined
                    dense
                    class="input-ajust r"
                    required
                  ></v-text-field>

                  <v-text-field
                    label="Orgão/Emissor"
                    :counter="4"
                    :rules="mandatory"
                    id="tellResi"
                    v-model="issuer"
                    :max="4"
                    placeholder="Ex: SSP"
                    outlined
                    dense
                    class="input-ajust r"
                    required
                  ></v-text-field>

                  <v-select
                    :items="sexualitys"
                    item-text="name"
                    :rules="mandatory"
                    item-value="id"
                    v-model="sexuality"
                    label="Sexualidade"
                    dense
                    outlined
                  ></v-select>
                </v-form>
              </v-card>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialog = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="nexTab()"> Continuar </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card class="mb-12 px-5 py-5" height="300px">
                <v-form ref="form2" lazy-validation class="tab-2-dialog">
                  <v-checkbox
                    v-model="checkedDeficiency"
                    label="Possui Deficiência?"
                  >
                  </v-checkbox>

                  <v-select
                    v-if="checkedDeficiency"
                    :rules="mandatorySelect"
                    :items="getAllDeficiencyc"
                    item-text="name"
                    item-value="id"
                    v-model="selectDeficiency"
                    label="Selecione"
                    multiple
                    outlined
                  ></v-select>

                  <v-checkbox
                    v-model="breastfeeding"
                    label="Estará Amamentando no período da Conferência?"
                  >
                  </v-checkbox>

                  <v-checkbox
                    v-model="escortActive"
                    label="Necessidade de Acompanhante?"
                  >
                  </v-checkbox>

                  <v-text-field
                    v-if="escortActive"
                    label="Nome"
                    :rules="nameRules"
                    :counter="60"
                    v-model="nameEscort"
                    id="name"
                    placeholder="Nome*"
                    outlined
                    dense
                    class="input-ajust"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-if="escortActive"
                    label="Nome Social"
                    v-model="nameSocialEscort"
                    :counter="60"
                    id="social"
                    placeholder="Nome Social"
                    outlined
                    dense
                    class="input-ajust"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-if="escortActive"
                    label="CPF"
                    masked="false"
                    v-mask.raw="'###.###.###-##'"
                    :rules="cpfRules"
                    v-model="cpfEscort"
                    id="CPF"
                    placeholder="CPF*"
                    outlined
                    dense
                    class="input-ajust"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-if="escortActive"
                    label="RG"
                    id="tellResi"
                    :rules="mandatory"
                    v-model="rgEscort"
                    v-mask="['##.###.###-##']"
                    placeholder="RG"
                    outlined
                    dense
                    required
                  ></v-text-field>

                  <v-select
                    v-if="escortActive"
                    :items="genreAllc"
                    item-text="name"
                    item-value="id"
                    id="sex"
                    :rules="mandatory"
                    v-model="genreEscort"
                    label="Gênero"
                    required
                    dense
                    outlined
                  ></v-select>
                </v-form>
              </v-card>
              <v-snackbar v-model="snackbar">
                {{ message2 }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="green"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialog = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="e1 = 1"> Voltar </v-btn>
                <v-btn color="primary" @click="nexTabTwo()"> continuar </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-card class="mb-12 px-5 py-5" height="300px">
                <v-form ref="form3" lazy-validation class="tab-3-dialog">
                  <v-text-field
                    label="Logradouro"
                    type="text"
                    :rules="nameLogradouro"
                    :counter="60"
                    placeholder="Logradouro"
                    v-model="publicPlace"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>

                  <v-text-field
                    label="CEP"
                    id="cep"
                    :rules="mandatory"
                    placeholder="CEP"
                    v-model="cep"
                    v-mask="'#####-###'"
                    outlined
                    dense
                    class="input-ajust grid-col-segund"
                    required
                  ></v-text-field>

                  <v-text-field
                    label="BAIRRO"
                    v-model="district"
                    placeholder="BAIRRO"
                    :rules="namesTrick"
                    :counter="40"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>

                  <v-select
                    :items="resource.getAllCounty"
                    item-text="name"
                    item-value="id"
                    v-model="municipaly"
                    :rules="mandatory"
                    class="input-ajust grid-col-segund"
                    label="Municipio"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-text-field
                    label="NÚMERO"
                    v-model="numberStreet"
                    type="number"
                    id="numberStreet"
                    :counter="4"
                    min="0"
                    placeholder="Número Residência"
                    :rules="numberResidencie"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>
                </v-form>
              </v-card>
              <v-snackbar v-model="snackbar">
                {{ message2 }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="green"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialog = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="e1 = 2"> Voltar </v-btn>
                <v-btn
                  color="primary"
                  :disabled="disabledSubmit"
                  @click="submitForm()"
                >
                  {{ buttom_name }}
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "@/axios";
import AlertError2 from "../alerts/Error2-component.vue";

export default {
  data() {
    return {
      dialog: false,
      dialogImportArchive: false,
      e1: 1,
      message: [],
      message2: [],
      disabledSubmit: false,

      breastfeeding: false,
      escortActive: false,

      displayError: {
        display: false,
        message: [],
      },

      displayError2: {
        display2: false,
        message2: [],
      },
      dialogImporResponse: false,
      // Route
      genreAllc: [],
      getAllDeficiencyc: [],
      snackbar: false,

      // Cadastro
      name: null,
      socialName: null,
      eixos: [],
      eixo: null,
      modality: 2,
      ownership: null,
      ownerships: [],
      modalitys: ["Presencial"],
      cpfCommission: "",
      segment: null,
      segments: [],
      birthDate: null,
      genre: null,
      breed: null,
      issuingAgency: null,
      rg: null,
      checkedDeficiency: false,
      selectDeficiency: [],
      tell: "",
      issuer: "",
      email: null,
      nameEscort: null,
      rgEscort: null,
      cpfEscort: null,
      nameSocialEscort: null,
      genderEscort: null,
      genreEscort: null,
      sexualitys: [],
      delegateCPF: null,
      id_cpf: null,
      id_rg: null,
      id_email: null,
      id_address: null,
      id_phone: null,
      delegate_id: null,
      requestType: "salvar",
      buttom_name: "CADASTRAR",
      archiveDelegateImport: "",
      disabledCPF: false,
      // Endereço
      publicPlace: null,
      cep: null,
      numberStreet: null,
      municipaly: null,
      district: null,
      archive: {},
      disableSubmitImportArchiveDelegates: false,
      itsAlreadyRegistered: [],
      itsNotRegisterable: [],
      itsRegisterable: [],
      dialogImportResponseMessage: "",
      //Estrutura
      formData: {
        people: {
          delegate_id: null,
          name: null,
          sexuality_id: null,
          county_id: null,
          gender_id: null,
          modality_id: null,
          birth_date: null,
          social_name: null,
          agency_id: null,
          breed_id: null,
          conference_id: null, //Id da conferência vinculada
          ownership_id: null,
          has_lactating: null,
          has_deficiency: null,
          axies: [],
          companion: [],
          deficiency: [],
        },
        address: {
          cep: null,
          county_id: null,
          public_place: null,
          address_number: null,
          district: null,
        },

        desserts: [
          {
            id: 1,
            title: "a quem sempre te deu a mao",
          },
          {
            id: 2,
            title: "com traica",
          },
          {
            id: 3,
            title: "vc pagou",
          },
        ],
        phone: [],
        documents: {
          RG: {
            document_number: null,
            issuing_body: null,
          },
          CPF: {
            document_number: null,
          },
        },
        email: {
          name: null,
        },
      },
      // Regras de validação
      mandatory: [(v) => !!v || "Campo obrigatório!"],
      mandatorySelect: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length >= 1) || "Campo obrigatório!",
      ],

      cpfRules: [
        (v) => !!v || "O CPF é obrigatório",
        (v) => (v && v.length <= 14) || "Você excedeu o tamanho máximo.",
      ],

      nameRules: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 60) || "Você excedeu o tamanho máximo.",
      ],

      numberResidencie: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 4) || "Você excedeu o tamanho máximo!",
        (v) => (v && v > 0) || "O número é abaixo do esperado",
      ],

      nameLogradouro: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
      ],

      namesTrick: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
      ],

      emailRules: [
        (v) => !!v || "O campo é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "O e-mail não é válido.",
        (v) => (v && v.length <= 70) || "Você excedeu o tamanho máximo.",
      ],
    };
  },

  components: {
    AlertError2,
  },

  props: {
    origin: {
      type: String,
    },
    resource: {
      type: Object,
    },
  },

  methods: {
    verifyIfConferenceIsEnded() {
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      if (
        this.$store.state.profile_id == 3 ||
        this.$store.state.profile_id == 4
      ) {
        let conference_date = new Date(
          this.$store.state.conference.final_county_phase_date.replace(
            /-/g,
            "/"
          )
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }

      if (this.$store.state.profile_id == 6) {
        let conference_date = new Date(
          this.$store.state.conference.final_macro_phase_date.replace(/-/g, "/")
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }

      if (this.$store.state.profile_id == 8) {
        let conference_date = new Date(
          this.$store.state.conference.final_state_phase_date.replace(/-/g, "/")
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }
    },
    onFileChange() {
      this.archive.archive_name = this.archiveDelegateImport.name;
      this.archive.archive_extension = this.archiveDelegateImport.type;
      this.archive.archive_blob = this.archiveDelegateImport.imageUrl;
      this.archive.size = this.archiveDelegateImport.size;
    },

    importArchive() {
      this.disableSubmitImportArchiveDelegates = true;
      let formData = new FormData();
      formData.append("state_id", this.$route.query.state_id);
      formData.append("conference_id", this.$route.query.id);
      formData.append("delegate_archive_import", this.archiveDelegateImport[0]);
      axios
        .post("import/delegates", formData)
        .then((response) => {
          this.itsAlreadyRegistered = response.data.itsAlreadyRegistered;
          this.itsNotRegisterable = response.data.itsNotRegisterable;
          this.itsRegisterable = response.data.itsRegisterable;
          this.dialogImportResponseMessage = response.data.message;
          this.dialogImporResponse = true;
          this.disableSubmitImportArchiveDelegates = false;
          this.dialogImportArchive = false;
          this.archiveDelegateImport = "";
          this.$emit("eventname", "");
        })
        .catch((err) => {
          setTimeout(() => {
            this.displayError2.display2 = false;
            this.disableSubmitImportArchiveDelegates = false;
            this.archiveDelegateImport = "";
          }, 5000);

          if (typeof err === "string") {
            this.displayError2.display2 = true;
            this.displayError2.message2 = err;
          }
          if (typeof err !== "string") {
            if (err.data.message) {
              this.$swal({
                icon: "error",
                title: "Erro ao cadastrar delegados",
                text: err.data.message,
              });
            }

            if (err.data.details) {
              this.$swal({
                icon: "error",
                title: "Erro ao cadastrar delegados",
                text: err.data.details,
              });
            }
          }
        });
    },

    getFunction() {
      axios.get("segment").then((res) => {
        this.segments = res.data;
      });
      // axios.get("modality").then((res) => {
      //   this.modalitys = res.data;
      // });
      axios.get("ownership").then((res) => {
        this.ownerships = res.data;
      });

      axios.get(`sexuality`).then((res) => {
        this.sexualitys = res.data;
      });
    },
    searchDelegateByCpf() {
      const newCPF = this.delegateCPF.replace(/[^a-zA-Z0-9]/g, "");
      axios.post("people/get-delegate-by-cpf", { cpf: newCPF }).then((res) => {
        let item = res.data;
        if (!item) {
          return;
        }
        if (typeof item !== "object") {
          this.disabledCPF = false;
        }
        if (typeof item === "object") {
          this.disabledCPF = true;
        }

        let cpf;
        let rg;
        cpf = this.getRgAndCpf(item.documents).cpf[0];
        rg = this.getRgAndCpf(item.documents).rg[0];

        this.id_cpf = cpf.id;
        if (rg) {
          this.id_rg = rg.id;
          this.rg = rg.document_number;
          this.issuer = rg.issuing_body;
        }

        this.cpfCommission = cpf.document_number;

        this.name = item.name;
        this.requestType = "editar";
        this.delegate_id = item.id;
        this.buttom_name = "ATUALIZAR";

        this.id_email = item.email[0].id;
        this.id_phone = item.phone[0].id;

        this.id_delegate = item.id;
        this.id_address = item.address[0].id;

        //Registro
        this.sexuality = Number(item.sexuality_id);
        this.socialName = item.social_name;

        this.birthDate =
          item.birth_date == null ? "" : item.birth_date.slice(0, 10);
        this.genre = Number(item.gender_id);
        this.breed = Number(item.breed_id);
        this.issuingAgency = Number(item.agency_id);
        this.tell = item.phone[0].phone_number + item.phone[0].ddd;
        this.email = item.email[0].name;

        //Acompanhante
        this.checkedDeficiency = Number(item.has_deficiency);

        this.selectDeficiency = [];
        for (let i = 0; i < item.deficiency.length; i++) {
          this.selectDeficiency.push(item.deficiency[i].id);
        }

        // Endereço
        this.publicPlace = item.address[0].public_place;
        this.cep = item.address[0].cep;
        this.numberStreet = item.address[0].address_number;
        this.municipaly = Number(item.address[0].county_id);
        this.district = item.address[0].district;
      });
    },

    getRgAndCpf(documents) {
      let rg;
      let cpf;
      cpf = documents.filter((x) => x.document_type_id == "1");
      rg = documents.filter((x) => x.document_type_id == "2");
      return { rg: rg, cpf: cpf };
    },

    submitForm() {
      if (!this.$refs.form.validate()) return;
      if (!this.$refs.form2.validate()) return;
      if (!this.$refs.form3.validate()) return;
      let phone_id = this.id_phone;
      function removeFirstTwoDigits(phoneNumber) {
        let ddd = phoneNumber.substring(0, 2);
        phoneNumber = phoneNumber.substring(2);
        if (phone_id == null) {
          return [{ ddd: ddd, phone_number: phoneNumber }];
        }

        return [{ id: phone_id, ddd: ddd, phone_number: phoneNumber }];
      }

      //REGEX
      const newCPF = this.cpfCommission.replace(/[^a-zA-Z0-9]/g, "");
      const newRG = this.rg.replace(/[^a-zA-Z0-9]/g, "");
      const newPhone = this.tell.replace(/[^\d]/g, "");
      const newCEP = this.cep.replace(/[^a-zA-Z0-9]/g, "");

      const newCPFCompania = this.cpfEscort
        ? this.cpfEscort.replace(/[^a-zA-Z0-9]/g, "")
        : null;
      const newRGCompania = this.rgEscort
        ? this.rgEscort.replace(/[^a-zA-Z0-9]/g, "")
        : null;

      const companion_ = [
        {
          social_name: this.nameSocialEscort,
          name: this.nameEscort,
          cpf: newCPFCompania,
          rg: newRGCompania,
          gender_id: this.genreEscort,
        },
      ];
      const axies_ = [this.eixo];

      if (this.requestType == "editar") {
        this.formData.people.county_id = this.county_id;
        this.formData.people.institute_id = this.institute_id;
        this.formData.people.regional_nucleus_health_id =
          this.regional_nucleus_health_id;
      }
      // First stap
      this.formData.people.requestType = this.requestType;
      this.formData.people.people_id = this.delegate_id;
      this.formData.documents.CPF.id = this.id_cpf;
      this.formData.documents.RG.id = this.id_rg;
      this.formData.email.id = this.id_email;
      this.formData.address.id = this.id_address;

      this.formData.people.conference_id = this.$route.query.id;
      this.formData.people.social_name = this.socialName;
      this.formData.people.name = this.name;
      this.formData.documents.CPF.document_number = newCPF;
      this.formData.people.birth_date = this.birthDate;
      this.formData.email.name = this.email;
      this.formData.phone = removeFirstTwoDigits(newPhone);

      if (this.origin == "county") {
        this.formData.people.county_id = this.$route.query.county_id;
      }
      if (this.origin == "institute") {
        this.formData.people.institute_id = this.$route.query.institute_id;
      }
      if (this.origin == "regional_nucleus") {
        this.formData.people.regional_nucleus_health_id =
          this.$route.query.regional_nucleus_health_id;
      }
      if (this.origin == "state") {
        this.formData.people.state_id = this.$route.query.state_id;
      }

      this.formData.people.sexuality_id = this.sexuality;

      this.formData.people.gender_id = this.genre;
      // this.formData.people.modality_id = this.modality;
      this.formData.people.modality_id = 2;
      this.formData.people.ownership_id = this.ownership;
      this.formData.people.segment_id = this.segment;
      this.formData.documents.RG.issuing_body = this.issuer;
      this.formData.documents.RG.document_number = newRG;
      this.formData.people.agency_id = this.issuingAgency;
      this.formData.people.breed_id = this.breed;

      this.formData.people.axies = axies_;
      // End First stap

      // Start Second Stap

      if (this.selectDeficiency.length >= 1) {
        this.formData.people.deficiency = this.selectDeficiency;
      } else {
        this.formData.people.deficiency = null;
      }

      this.formData.people.has_deficiency = this.checkedDeficiency;
      this.formData.people.has_lactating = this.breastfeeding;

      this.formData.people.companion = companion_;
      this.formData.people.companion.id = this.id_companion;
      this.formData.people.escortActive = this.escortActive;
      // End Second Stap

      // Start Endereço
      this.formData.address.cep = newCEP;
      this.formData.address.public_place = this.publicPlace;
      this.formData.address.address_number = this.numberStreet;
      this.formData.address.county_id = this.municipaly;
      this.formData.address.district = this.district;
      // End Endereço
      this.disabledSubmit = true;
      axios
        .post("people/delegates", this.formData)
        .then((res) => {
          this.disabledSubmit = true;
          this.$swal({
            icon: "success",
            title: res.data.message,
            text: "Delegado cadastrado!",
          });
          // this.snackbar = true;
          // this.message2 = res.data.message;
          this.$refs.form3.reset();
          this.$refs.form2.reset();
          this.$refs.form.reset();
          this.delegateCPF = "";
          this.formData.people = {};
          this.cpfCommission = "";
          this.birthDate = "";
          this.tell = "";
          // setTimeout(() => {
          this.disabledSubmit = false;
          this.e1 = 1;
          this.dialog = false;
          // }, 4000);
          this.requestType = "salvar";
          this.buttom_name = "CADASTRAR";
          this.disabledCPF = false;
          this.$emit("eventname", "");
        })
        .catch((err) => {
          setTimeout(() => {
            this.displayError2.display2 = false;
            this.disabledSubmit = false;
          }, 5000);

          if (typeof err === "string") {
            this.displayError2.message2 = err;
            this.displayError2.display2 = true;
          }

          if (typeof err !== "string") {
            if (err.data.message) {
              this.$swal({
                icon: "error",
                title: "Erro ao cadastrar delegados",
                text: err.data.message,
              });
            }

            if (err.data.details) {
              this.$swal({
                icon: "error",
                title: "Erro ao cadastrar delegados",
                text: err.data.details,
              });
            }
          }
          this.formData.people = {};
        });
    },

    loadSelects() {
      axios.get(`/county`).then((response) => {
        this.countyListOrgao = response.data;
      });

      axios.get(`/country`).then((response) => {
        this.countryListOrgao = response.data;
      });

      axios.get(`/state`).then((response) => {
        this.stateListOrgao = response.data;
      });

      axios.get(`conference/${this.$route.query.id}/axie`).then((res) => {
        this.eixos = res.data;
      });
    },

    resetForm() {
      this.requestType = "salvar";
      this.buttom_name = "CADASTRAR";
      this.$refs.form3.reset();
      this.$refs.form2.reset();
      this.$refs.form.reset();
      this.delegateCPF = "";
      this.formData.people = {};
      this.cpfCommission = "";
      this.birthDate = "";
      this.tell = "";
      this.disabledCPF = false;
    },

    openModalImportArchive() {
      this.dialogImportArchive = true;
      console.log("abrir modal import ");
    },

    openModal() {
      this.getFunction();
      this.loadSelects();
      this.resource.getAllDeficiency;
      this.genreAllc = this.resource.getAllGender;
      this.getAllDeficiencyc = this.resource.getAllDeficiency;
      this.dialog = true;
    },

    validate() {
      this.$refs.form.validate();
    },

    nexTab() {
      if (!this.$refs.form.validate()) return;
      this.e1 = 2;
    },
    nexTabTwo() {
      if (!this.$refs.form2.validate()) return;
      this.e1 = 3;
    },
  },
};
</script>

<style scoped>
.grid-col-ajust {
  grid-column: 1 / span 2 !important;
}

.r {
  grid-row: 5;
}

.grid-col-segund {
  grid-column: 3 / span 2;
}

.bnt-bottom-conf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tab-1-dialog {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 194px 210px 175px 185px;
  gap: 8px;
}

.tab-2-dialog {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 194px 210px 175px;
  gap: 8px;
}

.tab-3-dialog {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 117px 173px;
  gap: 8px;
}

.crash {
  grid-column: 1 / 3;
}

.columns-5-3 {
  grid-column: 5 / 3;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  background: -webkit-linear-gradient(to right, #27ae60, #1abc9c);
  background: linear-gradient(to right, #27ae60, #1abc9c);

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}

.card_response {
  overflow-y: scroll;
  overflow-x: hidden !important;
}
</style>