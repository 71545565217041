import { render, staticRenderFns } from "./table-facilitators.vue?vue&type=template&id=37477b08&scoped=true&"
import script from "./table-facilitators.vue?vue&type=script&lang=js&"
export * from "./table-facilitators.vue?vue&type=script&lang=js&"
import style0 from "./table-facilitators.vue?vue&type=style&index=0&id=37477b08&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37477b08",
  null
  
)

export default component.exports