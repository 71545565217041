<template>
  <div class="municipality">
    <v-card class="table-dashboard">
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :footer-props="{
          'items-per-page-text': 'Registros por Página',
        }"
        :page="page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :server-items-length="TotalResgister"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <div class="header-table">
                  <ButtonEntity v-show="permissionLvl2" />
                  <div class="header-space-table">
                    <DialogTableFacilitators
                      :origin="origin"
                      :resource="resource"
                      v-bind="attrs"
                      v-on="on"
                      v-on:eventname="getAllFacilitators()"
                      v-show="permissionLvl2"
                    />
                    <v-btn color="green" dark @click="getAllFacilitators()">
                      atualizar
                    </v-btn>
                  </div>
                </div>
              </template>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Tem certeza que deseja deletar este item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >cancelar</v-btn
                  >
                  <v-btn color="red darken-1" text @click="deleteItemConfirm"
                    >Deletar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template
          v-slot:item.actions="{ item }"
          v-if="
            $store.state.profile_id != 2 &&
            $store.state.profile_id != 21 &&
            $store.state.profile_id != 41
          "
        >
          <v-icon small class="mr-2" @click="editItem(item.facilitator, item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogEdit" max-width="780">
      <AlertError2
        class="mb-5"
        v-model="displayError2"
        :resource="displayError2"
      />
      <v-card>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 1" step="1">
              Cadastro
            </v-stepper-step>
            <v-divider> </v-divider>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12 px-5 py-5" height="380px">
                <v-form ref="form" lazy-validation class="tab-1-dialog">
                  <v-text-field
                    label="Nome"
                    :rules="nameRules"
                    :counter="60"
                    v-model="name"
                    id="name"
                    placeholder="Nome*"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust2"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Nome Social"
                    v-model="socialName"
                    :counter="60"
                    id="social"
                    placeholder="Nome Social"
                    outlined
                    dense
                    class="input-ajust a grid-col-segund"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="CPF"
                    masked="false"
                    v-mask.raw="'###.###.###-##'"
                    :disabled="true"
                    :rules="cpfRules"
                    v-model="cpfCommission"
                    id="CPF"
                    placeholder="CPF*"
                    outlined
                    dense
                    class="input-ajust crash2"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="E-mail*"
                    :counter="70"
                    :rules="emailRules"
                    v-model="email"
                    id="email"
                    type="email"
                    placeholder="E-mail"
                    outlined
                    dense
                    class="input-ajust crash2"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Telefone"
                    :rules="mandatory"
                    v-mask="['(##) ####-#####']"
                    v-model="tell"
                    id="date"
                    placeholder="Telefone"
                    outlined
                    dense
                    class="input-ajust a columns-5-3"
                    required
                  ></v-text-field>
                  <v-select
                    :items="resource.getAllCounty"
                    item-text="name"
                    item-value="id"
                    v-model="municipaly"
                    class="input-ajust grid-col-segund2"
                    label="Municipio"
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    style="max-width: 200px"
                    :items="resource.getAllEntity"
                    item-text="name"
                    :rules="mandatory"
                    item-value="id"
                    id="Ôrgão/Entidade"
                    class="grid-orgao"
                    v-model="issuingAgency"
                    label="Ôrgão/Entidade"
                    required
                    dense
                    outlined
                  ></v-select>

                  <v-select
                    :items="sexualitys"
                    item-text="name"
                    :rules="mandatory"
                    item-value="id"
                    v-model="sexuality"
                    label="Sexualidade"
                    dense
                    outlined
                  ></v-select>
                </v-form>
              </v-card>
              <v-snackbar v-model="snackbar">
                {{ message }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="green"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialogEdit = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="submitForm()"> EDITAR </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="snackbar">
      {{ message2 }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import DialogTableFacilitators from "../dialogs/dialog-table-facilitators.vue";
import AlertError2 from "../alerts/Error2-component.vue";
import ButtonEntity from "../dialogEdit/dialog-launch-all.vue";

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    loading: true,
    dialogEdit: false,
    items: [],
    options: {},
    sortBy: null,
    TotalResgister: null,
    numberOfPages: null,
    page: 1,
    headers: [
      { text: "AÇÕES", value: "actions", sortable: false },
      {
        text: "NOME",
        align: "start",
        sortable: false,
        value: "facilitator.name",
      },
      {
        text: "NOME SOCIAL",
        value: "facilitator.social_name",
        sortable: false,
      },
      {
        text: "TELEFONE",
        value: "facilitator.phone[0].phone_number",
        sortable: false,
      },
      { text: "MUNICÍPIO/RESIDÊNCIA", value: "county.name", sortable: false },
      {
        text: "ÓRGÃO/ENTIDADE",
        value: "facilitator.agency.name",
        sortable: false,
      },
    ],
    snackbar: false,

    e1: 1,
    message: [],
    message2: [],

    displayError2: {
      display2: false,
      message2: [],
    },

    // Cadastro
    name: null,
    socialName: null,
    cpfCommission: "",
    issuingAgency: null,
    sexualitys: [],
    sexuality: "",

    resultQuery: null,

    tell: "",
    email: null,
    municipaly: null,
    id_cpf: null,
    id_agency: null,
    id_email: null,
    id_tell: null,
    id_edit: null,
    id_address: null,
    id_delete: null,
    people_conference_id: null,
    permissionLvl2: null,

    //Estrutura
    formData: {
      people: {
        name: null,
        social_name: null,
        conference_id: null,
        agency_id: null,
        sexuality: null,
      },
      address: {
        county_id: null,
        id: null,
      },
      phone: [],
      documents: {
        CPF: {
          document_number: null,
        },
      },
      email: {
        name: null,
      },
    },
    // Regras de validação
    mandatory: [(v) => !!v || "Campo obrigatório!"],

    cpfRules: [
      (v) => !!v || "O CPF é obrigatório",
      (v) => (v && v.length <= 14) || "Você excedeu o tamanho máximo.",
    ],

    nameRules: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 60) || "Você excedeu o tamanho máximo.",
    ],

    numberResidencie: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 3) || "Você excedeu o tamanho máximo!",
    ],

    nameLogradouro: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
    ],

    namesTrick: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
    ],

    emailRules: [
      (v) => !!v || "O campo é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "O e-mail não é válido.",
      (v) => (v && v.length <= 70) || "Você excedeu o tamanho máximo.",
    ],
  }),

  props: {
    resource: {
      type: Object,
    },
    origin: {
      type: String,
    },
  },

  components: {
    DialogTableFacilitators,
    AlertError2,
    ButtonEntity,
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    sortBy: {
      handler() {
        console.log(this.sortBy);
      },
    },
    options: {
      handler() {
        this.getAllFacilitators();
      },
    },
    deep: true,
  },

  created() {
    this.getAllFacilitators();
    this.verification();
  },

  methods: {
    editItem(facilitator, people_conference) {
      this.loadSelects();
      this.sexuality = Number(facilitator.sexuality_id);
      this.id_address = facilitator.address[0]?.id;
      this.id_edit = facilitator.id;
      this.name = facilitator.name;
      this.socialName = facilitator.social_name;
      this.cpfCommission = this.getCpf(
        facilitator.documents
      )[0].document_number;
      this.id_cpf = facilitator.documents[0].id;
      this.issuingAgency = Number(facilitator.agency_id);
      this.people_conference_id = Number(people_conference.id);
      this.id_email = facilitator.email[0].id;
      this.email = facilitator.email[0].name;
      this.id_tell = facilitator.phone[0].id;
      this.tell = facilitator.phone[0].ddd + facilitator.phone[0].phone_number;
      this.municipaly = Number(people_conference.county_id);
      this.dialogEdit = true;
    },

    getCpf(documents) {
      return documents.filter((x) => x.document_type_id == 1);
    },
    verification() {
      if (
        Number(this.$store.state.finished_release_conference) &&
        Number(this.$store.state.restrict_release_conference)
      ) {
        this.permissionLvl2 =
          Number(this.$store.state.profile_id) == 1 ? true : false;
      } else {
        this.permissionLvl2 = true;
      }
    },

    submitForm() {
      if (!this.$refs.form.validate()) return;

      function removeFirstTwoDigits(phoneNumber, id_tell) {
        let ddd = phoneNumber.substring(0, 2);
        phoneNumber = phoneNumber.substring(2);
        return [{ ddd: ddd, phone_number: phoneNumber, id: id_tell }];
      }

      //REGEX
      const newCPF = this.cpfCommission.replace(/[^a-zA-Z0-9]/g, "");
      const newPhone = this.tell.replace(/[^\d]/g, "");
      this.formData.people.people_conference_id = this.people_conference_id;
      this.formData.people.conference_id = this.$route.query.id;
      this.formData.people.social_name = this.socialName;
      this.formData.people.name = this.name;
      this.formData.email.name = this.email;
      this.formData.email.id = this.id_email;
      this.formData.documents.CPF.document_number = newCPF;
      this.formData.documents.CPF.id = this.id_cpf;
      this.formData.people.agency_id = this.issuingAgency;
      this.formData.phone = removeFirstTwoDigits(newPhone, this.id_tell);

      // Endereço
      this.formData.address.county_id = this.municipaly;
      this.formData.address.id = this.id_address;

      axios
        .put(`people/${this.id_edit}/facilitators`, this.formData)
        .then((res) => {
          this.displayError2.display2 = false;
          this.snackbar = true;
          this.message = res.data.message;
          this.$refs.form.reset();
          this.dialogEdit = false;
          setTimeout(() => {
            this.e1 = 1;
          }, 4000);
        })
        .catch((err) => {
          err;
          var error = null;
          Object.values(this.$store.state.errorMessage).map((value) => {
            error = value;
          });
          this.displayError2.message2 = error.toString();
          this.displayError2.display2 = true;
        });
    },

    loadSelects() {
      axios.get(`/county`).then((response) => {
        this.countyListOrgao = response.data;
      });

      axios.get(`/country`).then((response) => {
        this.countryListOrgao = response.data;
      });

      axios.get(`/state`).then((response) => {
        this.stateListOrgao = response.data;
      });

      axios.get(`sexuality`).then((res) => {
        this.sexualitys = res.data;
      });
    },

    resetForm() {
      this.$refs.form3.reset();
      this.$refs.form3.resetValidation();
    },

    getAllFacilitators() {
      this.loading = true;

      const { page } = this.options;
      let pageNumber = page;
      let data = new Object();
      data.per_page = this.options.itemsPerPage;

      if (pageNumber == undefined) {
        pageNumber = 1;
      }
      if (data.per_page == undefined) {
        data.per_page = 10;
      }

      if (this.origin == "institute") {
        this.resultQuery = `conference/${this.$route.query.id}/facilitators?page=${pageNumber}&per_page=${data.per_page}&institute_id=${this.$route.query.institute_id}`;
      }

      if (this.origin == "county") {
        this.resultQuery = `conference/${this.$route.query.id}/facilitators?page=${pageNumber}&per_page=${data.per_page}&county_id=${this.$route.query.county_id}`;
      }

      if (this.origin == "regional_nucleus") {
        this.resultQuery = `conference/${this.$route.query.id}/facilitators?page=${pageNumber}&per_page=${data.per_page}&regional_nucleus_health_id=${this.$route.query.regional_nucleus_health_id}`;
      }

      if (this.origin == "state") {
        this.resultQuery = `conference/${this.$route.query.id}/facilitators?page=${pageNumber}&per_page=${data.per_page}&state_id=${this.$route.query.state_id}`;
      }

      axios.get(this.resultQuery).then((res) => {
        this.loading = false;
        this.items = res.data.data;
        this.TotalResgister = res.data.total;
        this.numberOfPages = res.data.last_page;
      });
    },

    deleteItem(item) {
      this.id_delete = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      axios
        .delete(`people-conference/${this.id_delete}`)
        .then((res) => {
          this.getAllFacilitators();
          this.snackbar = true;
          this.message2 = res.data.message;
          this.dialogDelete = false;
        })
        .catch((err) => {
          err;
          var error = "";
          Object.values(this.$store.state.errorMessage).map((value) => {
            error = value;
          });
          this.displayError2.message2 = error.toString();
          this.displayError2.display2 = true;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
<style scoped>
.delegate-button {
  margin-right: 30px;
  margin-left: 20px;
  display: flex;
  width: 650px;
  justify-content: center;
  align-items: center;
}

.header-table {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.header-space-table {
  display: flex;
  justify-self: center;
  align-items: center;
  gap: 20px;
  margin: 0 20px;
}

.a {
  grid-column: 3/5;
}

.grid-col-ajust2 {
  grid-column: 1 / span 2;
}

.grid-col-segund2 {
  grid-column: 1 / span 2;
}

.bnt-bottom-conf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.row + .row {
  margin: 0px !important;
}

.tab-1-dialog {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 20%;
  gap: 8px;
}

.crash2 {
  grid-column: 1 / 3;
}

.grid-button {
  grid-row: 2;
  grid-column: 4;
}

.grid-orgao {
  grid-row: 2;
  grid-column: 3 / span 2;
}

.columns-5-3-2 {
  grid-column: 5 / 3;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  background: -webkit-linear-gradient(to right, #27ae60, #1abc9c);
  background: linear-gradient(to right, #27ae60, #1abc9c);

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>