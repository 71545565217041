<template>
  <v-alert
    width="100%"
    border="left"
    colored-border
    type="error"
    elevation="1"
    v-model="resource.display2"
  >
    <h6 class="alertaText">{{ resource.message2 }}</h6>
    <v-btn
      class="btn-close"
      small
      color="red"
      text
      @click="resource.display2 = false"
    >
      FECHAR
    </v-btn>
  </v-alert>
</template>

<script>
export default {
  data() {
    return {
      teste: 3000,
    };
  },

  props: {
    resource: {
      type: [Array, Object, String],
    },
  },
};
</script>

<style scoped>
.v-alert {
  margin-bottom: 10px;
}

.v-alert__content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alertaText {
  font-size: 12px;
  font-weight: 500;
}
</style>
