<template>
  <v-row justify="center">
    <v-btn
      color="primary"
      v-if="
        $store.state.profile_id != 2 &&
        $store.state.profile_id != 21 &&
        $store.state.profile_id != 41
      "
      v-show="!verifyIfConferenceIsEnded()"
      dark
      @click.stop="openModal()"
    >
      Cadastrar FACILITADORES
    </v-btn>

    <v-dialog v-model="dialog" max-width="780">
      <v-card>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <AlertError2
              class="mb-5"
              v-model="displayError2"
              :resource="displayError2"
            />
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 1" step="1">
              Cadastro
            </v-stepper-step>
            <v-divider> </v-divider>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <div style="display: flex; justify-content: center">
                <div class="px-5 py-5" style="width: 400px">
                  <v-text-field
                    label="Pesquisar facilitador por CPF"
                    v-model="facilitatorCPF"
                    v-mask.raw="'###.###.###-##'"
                    placeholder="Pesquisar facilitador por CPF"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </div>
                <div class="px-3 py-3">
                  <v-btn
                    class="mt-2"
                    color="primary"
                    @click="searchDelegateByCpf()"
                  >
                    Pesquisar
                  </v-btn>
                  <v-btn
                    class="mt-2 ml-2"
                    color="#C62828"
                    style="color: white"
                    @click="resetInputs()"
                  >
                    Limpar
                  </v-btn>
                </div>
              </div>

              <v-card class="mb-12 px-5 py-5" height="380px">
                <v-form ref="form" lazy-validation class="tab-1-dialog">
                  <v-text-field
                    label="Nome"
                    :rules="nameRules"
                    :counter="60"
                    v-model="name"
                    id="name"
                    placeholder="Nome*"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust2"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Nome Social"
                    v-model="socialName"
                    :counter="60"
                    id="social"
                    placeholder="Nome Social"
                    outlined
                    dense
                    class="input-ajust a grid-col-segund"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="CPF"
                    v-mask.raw="'###.###.###-##'"
                    :rules="cpfRules"
                    :disabled="disabledCPF"
                    v-model="cpfCommission"
                    id="CPF"
                    placeholder="CPF*"
                    outlined
                    dense
                    class="input-ajust crash2"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="E-mail*"
                    :counter="70"
                    :rules="emailRules"
                    v-model="email"
                    id="email"
                    type="email"
                    placeholder="E-mail"
                    outlined
                    dense
                    class="input-ajust crash2"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Telefone"
                    :rules="mandatory"
                    v-mask="['(##) ####-#####']"
                    v-model="tell"
                    id="date"
                    placeholder="Telefone"
                    outlined
                    dense
                    class="input-ajust a columns-5-3"
                    required
                  ></v-text-field>
                  <v-select
                    :items="resource.getAllCounty"
                    item-text="name"
                    item-value="id"
                    v-model="municipaly"
                    class="input-ajust grid-col-segund2"
                    label="Municipio"
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    style="max-width: 200px;"
                    :items="resource.getAllEntity"
                    item-text="name"
                    :rules="mandatory"
                    item-value="id"
                    id="Ôrgão/Entidade"
                    class="grid-orgao"
                    v-model="issuingAgency"
                    label="Ôrgão/Entidade"
                    required
                    dense
                    outlined
                  ></v-select>

                  <v-select
                    :items="sexualitys"
                    item-text="name"
                    :rules="mandatory"
                    item-value="id"
                    v-model="sexuality"
                    label="Sexualidade"
                    dense
                    outlined
                  ></v-select>
                </v-form>
              </v-card>
              <v-snackbar v-model="snackbar">
                {{ message }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="green"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialog = false"> Cancelar </v-btn>
                <v-btn
                  color="primary"
                  :disabled="disabledButton"
                  @click="submitForm()"
                >
                  {{ buttom_name }}
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "@/axios";
import AlertError2 from "../alerts/Error2-component.vue";

export default {
  data() {
    return {
      dialog: false,
      snackbar: false,
      disabledButton: false,

      e1: 1,
      message: [],
      message2: [],

      displayError2: {
        display2: false,
        message2: [],
      },

      // Cadastro
      name: null,
      allFunctionCommission: [],
      socialName: null,
      cpfCommission: "",
      issuingAgency: null,
      tell: "",
      email: null,
      county_id: null,
      institute_id: null,
      regional_nucleus_health_id: null,
      disabledCPF: false,
      // Endereço
      municipaly: null,
      sexualitys: [],
      facilitatorCPF: "",
      id_cpf: null,
      requestType: "salvar",
      facilitator_id: null,
      buttom_name: "CADASTRAR",
      sexuality: null,
      id_address: null,
      id_edit: null,
      id_email: null,
      id_tell: null,

      //Estrutura
      formData: {
        people: {
          name: null,
          social_name: null,
          gender_id: null,
          county_id: null,
          conference_id: null,
          agency_id: null,
          sexuality: null,
        },
        address: {
          county_id: null,
        },
        phone: [],
        documents: {
          CPF: {
            document_number: null,
          },
        },
        email: {
          name: null,
        },
      },
      // Regras de validação
      mandatory: [(v) => !!v || "Campo obrigatório!"],

      cpfRules: [
        (v) => !!v || "O CPF é obrigatório",
        (v) => (v && v.length <= 14) || "Você excedeu o tamanho máximo.",
      ],

      nameRules: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 60) || "Você excedeu o tamanho máximo.",
      ],

      numberResidencie: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 3) || "Você excedeu o tamanho máximo!",
      ],

      nameLogradouro: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
      ],

      namesTrick: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
      ],

      emailRules: [
        (v) => !!v || "O campo é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "O e-mail não é válido.",
        (v) => (v && v.length <= 70) || "Você excedeu o tamanho máximo.",
      ],
    };
  },

  components: {
    AlertError2,
  },

  props: {
    resource: {
      type: Object,
    },
    origin: {
      type: String,
    },
  },

  methods: {
    verifyIfConferenceIsEnded() {
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      if (
        this.$store.state.profile_id == 3 ||
        this.$store.state.profile_id == 4
      ) {
        let conference_date = new Date(
          this.$store.state.conference.final_county_phase_date.replace(
            /-/g,
            "/"
          )
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }

      if (this.$store.state.profile_id == 6) {
        let conference_date = new Date(
          this.$store.state.conference.final_macro_phase_date.replace(/-/g, "/")
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }

      if (this.$store.state.profile_id == 8) {
        let conference_date = new Date(
          this.$store.state.conference.final_state_phase_date.replace(/-/g, "/")
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }
    },
    getRgAndCpf(documents) {
      let cpf;
      cpf = documents.filter((x) => x.document_type_id == "1");
      return { cpf: cpf };
    },

    searchDelegateByCpf() {
      const newCPF = this.facilitatorCPF.replace(/[^a-zA-Z0-9]/g, "");
      axios
        .post("people/get-facilitator-by-cpf", { cpf: newCPF })
        .then((res) => {
          let item = res.data;
          if (!item) {
            return;
          }
          if (typeof item !== "object") {
            this.disabledCPF = false;
          }
          if (typeof item === "object") {
            this.disabledCPF = true;
          }

          let cpf;
          cpf = this.getRgAndCpf(item.documents).cpf[0];
          this.id_cpf = cpf.id;

          this.name = item.name;
          this.requestType = "editar";
          this.facilitator_id = item.id;
          this.buttom_name = "ATUALIZAR";

          this.sexuality = Number(item.sexuality_id);

          if (Array.isArray(item.address) && item.address.length) {
            this.id_address = item.address[0].id;
            this.municipaly = Number(item.address[0].county_id);
          }

          this.id_edit = item.id;
          this.socialName = item.social_name;
          this.cpfCommission = cpf.document_number;
          this.issuingAgency = Number(item.agency_id);

          this.id_email = item.email[0].id;
          this.email = item.email[0].name;
          this.id_tell = item.phone[0].id;
          this.tell = item.phone[0].ddd + item.phone[0].phone_number;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    submitForm() {
      if (!this.$refs.form.validate()) return;

      let phone_id = this.id_tell;
      function removeFirstTwoDigits(phoneNumber) {
        let ddd = phoneNumber.substring(0, 2);
        phoneNumber = phoneNumber.substring(2);
        if (phone_id == null) {
          return [{ ddd: ddd, phone_number: phoneNumber }];
        }
        return [{ id: phone_id, ddd: ddd, phone_number: phoneNumber }];
      }

      //REGEX
      const newCPF = this.cpfCommission.replace(/[^a-zA-Z0-9]/g, "");
      const newPhone = this.tell.replace(/[^\d]/g, "");

      if (this.requestType == "editar") {
        this.formData.people.county_id = this.county_id;
        this.formData.people.institute_id = this.institute_id;
        this.formData.people.regional_nucleus_health_id =
          this.regional_nucleus_health_id;
      }

      this.formData.people.requestType = this.requestType;
      this.formData.people.people_id = this.facilitator_id;
      this.formData.people.conference_id = this.$route.query.id;
      this.formData.people.social_name = this.socialName;
      this.formData.people.name = this.name;
      this.formData.people.sexuality_id = this.sexuality;

      this.formData.email.id = this.id_email;
      this.formData.email.name = this.email;

      if (this.origin == "institute") {
        this.formData.people.institute_id = this.$route.query.institute_id;
      }
      if (this.origin == "county") {
        this.formData.people.county_id = this.$route.query.county_id;
      }
      if (this.origin == "regional_nucleus") {
        this.formData.people.regional_nucleus_health_id =
          this.$route.query.regional_nucleus_health_id;
      }
      if (this.origin == "state") {
        this.formData.people.state_id = this.$route.query.state_id;
      }

      this.formData.documents.CPF.id = this.id_cpf;
      this.formData.documents.CPF.document_number = newCPF;
      this.formData.people.agency_id = this.issuingAgency;
      this.formData.phone = removeFirstTwoDigits(newPhone);

      // Endereço
      this.formData.address.id = this.id_address;
      this.formData.address.county_id = this.municipaly;
      this.disabledButton = true;
      axios
        .post("people/facilitators", this.formData)
        .then((res) => {
          this.displayError2.display2 = false;
          // this.snackbar = true;
          // this.message = res.data.message;
          this.$refs.form.reset();
          this.formData.people = {};
          this.facilitatorCPF = "";
          this.disabledCPF = false;
          // setTimeout(() => {
          this.e1 = 1;
          this.dialog = false;
          this.disabledButton = false;
          // }, 4000);
          this.$swal({
            icon: "success",
            title: res.data.message,
            text: "Facilitador cadastrado!",
          });
          this.requestType = "salvar";
          this.buttom_name = "CADASTRAR";
          this.$emit("eventname", "");
        })
        .catch((err) => {
          setTimeout(() => {
            this.displayError2.display2 = false;
            this.disabledButton = false;
          }, 5000);

          if (typeof err === "string") {
            this.displayError2.message2 = err;
            this.displayError2.display2 = true;
          }

          if (typeof err !== "string") {
            if (err.data.message) {
              this.$swal({
                icon: "error",
                title: "Erro ao cadastrar facilitadores",
                text: err.data.message,
              });
            }

            if (err.data.details) {
              this.$swal({
                icon: "error",
                title: "Erro ao cadastrar facilitadores",
                text: err.data.details,
              });
            }
          }
        });
    },

    loadSelects() {
      axios.get(`/county`).then((response) => {
        this.countyListOrgao = response.data;
      });

      axios.get(`/country`).then((response) => {
        this.countryListOrgao = response.data;
      });

      axios.get(`/state`).then((response) => {
        this.stateListOrgao = response.data;
      });

      axios.get(`sexuality`).then((res) => {
        this.sexualitys = res.data;
      });
    },

    resetForm() {
      this.$refs.form3.reset();
      this.$refs.form3.resetValidation();
    },
    resetInputs() {
      this.name = "";
      this.socialName = "";
      this.cpfRules = "";
      this.cpfCommission = "";
      this.email = "";
      this.tell = "";
      this.municipaly = "";
      this.issuingAgency = "";
      this.sexuality = "";
      this.facilitatorCPF = "";
      this.requestType = "salvar";
      this.buttom_name = "CADASTRAR";
      this.disabledCPF = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    openModal() {
      this.loadSelects();
      this.resource.getAllDeficiency;
      this.genreAllc = this.resource.getAllGender;
      this.getAllDeficiencyc = this.resource.getAllDeficiency;
      this.dialog = true;
    },

    validate() {
      this.$refs.form.validate();
    },

    nexTab() {
      if (!this.$refs.form.validate()) return;
      this.e1 = 2;
    },
  },
};
</script>

<style scoped>
.a {
  grid-column: 3/5;
}

.grid-col-ajust2 {
  grid-column: 1 / span 2;
}

.grid-col-segund2 {
  grid-column: 1 / span 2;
}

.bnt-bottom-conf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tab-1-dialog {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 150px 175px auto;
  gap: 8px;
}

.crash2 {
  grid-column: 1 / 3;
}

.grid-button {
  grid-row: 2;
  grid-column: 4;
}

.grid-orgao {
  grid-row: 2;
  grid-column: 3 / span 2;
}

.columns-5-3-2 {
  grid-column: 5 / 3;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.row + .row {
  margin: 0px !important;
}

.banner {
  background: #b3ffab;
  background: -webkit-linear-gradient(to right, #27ae60, #1abc9c);
  background: linear-gradient(to right, #27ae60, #1abc9c);

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>