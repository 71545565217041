<template>
  <div class="municipality">
    <v-card class="table-dashboard">
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :page="page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :server-items-length="TotalResgister"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <div class="delegate-button">
                  <div>
                    <DialogTableAnexos
                      :origin="origin"
                      v-show="permissionLvl2"
                      :resource="resource"
                      v-bind="attrs"
                      v-on:eventname="getAll()"
                      v-on="on"
                    />
                    <v-btn color="green" dark @click="getAll()">
                      atualizar
                    </v-btn>
                  </div>
                </div>
              </template>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Tem certeza que deseja deletar este item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogDelete = false"
                    >cancelar</v-btn
                  >
                  <v-btn color="red darken-1" text @click="deleteItemConfirm"
                    >Deletar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          <span>{{ new Date(item.created_at).toLocaleString() }}</span>
        </template>
        <template
          v-slot:item.actions="{ item }"
          v-if="
            $store.state.profile_id != 2 &&
            $store.state.profile_id != 21 &&
            $store.state.profile_id != 41
          "
        >
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          <v-icon small @click="downloadFile(item)"> mdi-file-download </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import DialogTableAnexos from "../dialogs/dialog-table-file.vue";

export default {
  data: () => ({
    dialog: false,
    id_anexo: null,
    loading: false,
    message: [],
    snackbar: false,
    permissionLvl2: false,
    page: 1,
    numberOfPages: null,
    TotalResgister: null,
    e1: 1,
    resultQuery: null,
    sortBy: "archive_name",
    options: {},
    items: [],
    dialogDelete: false,
    headers: [
      { text: "AÇÕES", value: "actions", sortable: false },
      {
        text: "TÍTULO",
        align: "start",
        sortable: false,
        value: "archive_name",
      },
      { text: "EXTENSÃO", value: "archive_extension" },
      { text: "TAMANHO", value: "archive_size" },
      { text: "DATA DE ENVIO", value: "created_at" },
    ],
  }),

  props: {
    resource: {
      type: Object,
    },
    origin: {
      type: String,
    },
  },

  components: {
    DialogTableAnexos,
  },

  watch: {
    sortBy: {
      handler() {
        console.log(this.sortBy);
      },
    },
    options: {
      handler() {
        this.getAll();
      },
    },
    deep: true,

    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getAll();
    this.verification();
  },

  methods: {
    getAll() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;
      let data = new Object();
      data.per_page = this.options.itemsPerPage;

      if (pageNumber == undefined) {
        pageNumber = 1;
      }
      if (data.per_page == undefined) {
        data.per_page = 10;
      }

      if (this.origin == "institute") {
        this.resultQuery = `conference/${this.$route.query.id}/archive?page=${pageNumber}&per_page=${data.per_page}&institute_id=${this.$route.query.institute_id}`;
      }
      if (this.origin == "county") {
        this.resultQuery = `conference/${this.$route.query.id}/archive?page=${pageNumber}&per_page=${data.per_page}&county_id=${this.$route.query.county_id}`;
      }
      if (this.origin == "regional_nucleus") {
        this.resultQuery = `conference/${this.$route.query.id}/archive?page=${pageNumber}&per_page=${data.per_page}&regional_nucleus_health_id=${this.$route.query.regional_nucleus_health_id}`;
      }
      if (this.origin == "state") {
        this.resultQuery = `conference/${this.$route.query.id}/archive?page=${pageNumber}&per_page=${data.per_page}&state_id=${this.$route.query.state_id}`;
      }

      axios.get(this.resultQuery).then((res) => {
        this.loading = false;
        this.items = res.data.data;
        this.TotalResgister = res.data.total;
        this.numberOfPages = res.data.last_page;
      });
    },

    deleteItem(item) {
      this.dialogDelete = true;
      this.id_anexo = item.id;
    },

    downloadFile(item) {
      let obj = new Object();
      obj.archive_id = item.id;
      axios
        .post("archives/download", obj, { responseType: "blob" })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = item.archive_name;
          link.click();
          URL.revokeObjectURL(link.href);
          //fileDownload(res.data, 'relatorio.xls');
        });
    },

    deleteItemConfirm() {
      axios
        .delete(`archives/${this.id_anexo}`)
        .then((res) => {
          this.getAll();
          this.snackbar = true;
          this.message = res.data.message;
          this.dialogDelete = false;
        })
        .catch((err) => {
          err;
          var error = "";
          Object.values(this.$store.state.errorMessage).map((value) => {
            error = value;
          });
          this.message = error.toString();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    verification() {
      if (
        Number(this.$store.state.finished_release_conference) &&
        Number(this.$store.state.restrict_release_conference)
      ) {
        this.permissionLvl2 =
          Number(this.$store.state.profile_id) == 1 ? true : false;
      } else {
        this.permissionLvl2 = true;
      }
    },
  },
};
</script>
<style scoped>
.delegate-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: right !important;
  margin: 0 20px;
}

.delegate-button div {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
</style>