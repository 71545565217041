<template>
  <div justify="center" class="mtop">
    <v-btn
      v-show="!verifyIfConferenceIsEnded()"
      color="primary"
      v-if="
        $store.state.profile_id != 2 &&
        $store.state.profile_id != 21 &&
        $store.state.profile_id != 41
      "
      dark
      @click.stop="openModal()"
    >
      Cadastrar COMISSÃO
    </v-btn>

    <v-dialog v-model="dialog" max-width="780">
      <AlertError2
        class="mb-5"
        v-model="displayError2"
        :resource="displayError2"
      />

      <v-card>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Cadastro
            </v-stepper-step>
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 2" step="2">
              Endereço
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row>
                <v-col cols="6" class="px-5 py-5">
                  <v-text-field
                    label="Pesquisar comissão por CPF"
                    v-model="comissionCPF"
                    v-mask.raw="'###.###.###-##'"
                    placeholder="Pesquisar comissão por CPF"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    class="mt-2"
                    color="primary"
                    @click="searchComissionByCpf()"
                  >
                    Pesquisar
                  </v-btn>
                  <v-btn
                    class="mt-2 ml-2"
                    color="#C62828"
                    style="color: white"
                    @click="resetForm()"
                  >
                    Limpar
                  </v-btn>
                </v-col>
              </v-row>
              <v-card class="mb-12 px-5 py-5" height="480px">
                <v-form ref="form" lazy-validation class="tab-1-dialog">
                  <v-text-field
                    label="Nome"
                    :rules="nameRules"
                    :counter="60"
                    v-model="name"
                    id="name"
                    placeholder="Nome*"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Nome Social"
                    v-model="socialName"
                    :counter="60"
                    id="social"
                    placeholder="Nome Social"
                    outlined
                    dense
                    class="input-ajust grid-col-segund"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="CPF"
                    masked="false"
                    :disabled="disabledCPF"
                    v-mask.raw="'###.###.###-##'"
                    :rules="cpfRules"
                    v-model="cpfCommission"
                    id="CPF"
                    placeholder="CPF*"
                    outlined
                    dense
                    class="input-ajust crash"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Data de nascimento"
                    v-model="birthDate"
                    type="date"
                    id="date"
                    placeholder="Data de Nascimento"
                    outlined
                    dense
                    class="input-ajust columns-5-3"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="E-mail*"
                    :counter="70"
                    :rules="emailRules"
                    v-model="email"
                    id="email"
                    type="email"
                    placeholder="E-mail"
                    outlined
                    dense
                    class="input-ajust crash"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Telefone"
                    :rules="mandatory"
                    v-model="tell"
                    v-mask="['(##) ####-#####']"
                    id="date"
                    placeholder="Telefone"
                    outlined
                    dense
                    class="input-ajust columns-5-3"
                    required
                  ></v-text-field>
                  <v-select
                    :items="genreAllc"
                    item-text="name"
                    item-value="id"
                    id="sex"
                    :rules="mandatory"
                    v-model="genre"
                    label="Gênero"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-text-field
                    label="Religião"
                    type="text"
                    v-model="religion"
                    id="religion"
                    placeholder="Religião"
                    outlined
                    dense
                    required
                  ></v-text-field>
                  <v-select
                    :items="allFunctionCommission"
                    id="instruction"
                    class="input-ajust columns-5-3"
                    v-model="functionCommision"
                    :rules="mandatory"
                    item-text="name"
                    item-value="id"
                    label="Função Comissão*"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    :items="resource.getAllBreed"
                    :rules="mandatory"
                    item-text="name"
                    item-value="id"
                    id="breed"
                    v-model="breed"
                    label="Raça/Cor"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    style="max-width: 200px"
                    :items="updated"
                    item-text="name"
                    :rules="mandatory"
                    item-value="id"
                    id="Ôrgão/Entidade"
                    v-model="issuingAgency"
                    label="Ôrgão/Entidade"
                    required
                    dense
                    outlined
                  ></v-select>

                  <v-text-field
                    label="RG"
                    id="tellResi"
                    :rules="mandatory"
                    v-model="rg"
                    v-mask="['##.###.###-##']"
                    placeholder="RG"
                    outlined
                    dense
                    class="input-ajust"
                    required
                  ></v-text-field>

                  <v-text-field
                    label="Orgão/Emissor"
                    :counter="4"
                    :rules="mandatory"
                    id="tellResi"
                    v-model="issuer"
                    maxlength="4"
                    placeholder="Ex: SSP"
                    outlined
                    dense
                    class="input-ajust"
                    required
                  ></v-text-field>

                  <v-select
                    :items="sexualitys"
                    item-text="name"
                    :rules="mandatory"
                    item-value="id"
                    v-model="sexuality"
                    label="Sexualidade"
                    dense
                    outlined
                  ></v-select>

                  <v-checkbox
                    v-model="checkedDeficiency"
                    label="Possui Deficiência?"
                    class="checked"
                  >
                  </v-checkbox>
                  <v-select
                    v-if="checkedDeficiency"
                    :rules="mandatory"
                    :items="getAllDeficiencyc"
                    item-text="name"
                    item-value="id"
                    v-model="selectDeficiency"
                    multiple
                    label="Selecione"
                    outlined
                  ></v-select>
                </v-form>
              </v-card>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialog = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="nexTab()"> Continuar </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card class="mb-12 px-5 py-5" height="300px">
                <v-form ref="form2" lazy-validation class="tab-1-dialog">
                  <v-text-field
                    label="Logradouro"
                    type="text"
                    :rules="nameLogradouro"
                    :counter="60"
                    placeholder="Logradouro"
                    v-model="publicPlace"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>

                  <v-text-field
                    label="CEP"
                    id="cep"
                    :rules="mandatory"
                    placeholder="CEP"
                    v-model="cep"
                    v-mask="'#####-###'"
                    outlined
                    dense
                    class="input-ajust grid-col-segund"
                    required
                  ></v-text-field>

                  <v-text-field
                    label="BAIRRO"
                    v-model="district"
                    placeholder="BAIRRO"
                    :rules="namesTrick"
                    :counter="40"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>

                  <v-select
                    :items="resource.getAllCounty"
                    item-text="name"
                    item-value="id"
                    v-model="municipaly"
                    :rules="mandatory"
                    class="input-ajust grid-col-segund"
                    label="Municipio"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-text-field
                    label="NÚMERO"
                    v-model="numberStreet"
                    type="number"
                    id="numberStreet"
                    :counter="4"
                    min="0"
                    placeholder="Número Residência"
                    :rules="numberResidencie"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>
                </v-form>
                <v-dialog v-model="dialogOrgao" width="600px" persistent>
                  <v-card class="create-user-dialog rounded-lg">
                    <div class="banner">
                      <h4>Criar Orgão Entidade</h4>
                    </div>

                    <AlertError
                      class="mb-5"
                      v-model="displayError"
                      :resource="displayError"
                    />
                    <v-snackbar v-model="snackbarOrgao">
                      {{ message }}
                      <template v-slot:action="{ attrs }">
                        <v-btn
                          color="green"
                          text
                          v-bind="attrs"
                          @click="snackbarOrgao = false"
                        >
                          Fechar
                        </v-btn>
                      </template>
                    </v-snackbar>

                    <v-form @submit.prevent="create" ref="form3">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            class="rounded-lg"
                            label="Nome da Orgão/entidade"
                            placeholder="Orgão Entidade"
                            outlined
                            v-model="nameOrgao"
                            :rules="mandatory"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            class="rounded-lg"
                            label="Responsável"
                            placeholder="Responsável"
                            outlined
                            v-model="responsibleOrgao"
                            :rules="mandatory"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            type="email"
                            class="rounded-lg"
                            label="Email"
                            placeholder="Email"
                            outlined
                            v-model="emailOrgao"
                            :rules="emailRules"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            class="rounded-lg"
                            label="CNPJ"
                            placeholder="CNPJ"
                            outlined
                            v-model="cnpjOrgao"
                            v-mask="'##.###.###/####-##'"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            class="rounded-lg"
                            label="Telefone"
                            placeholder="Telefone"
                            v-mask="'(##) #####-####'"
                            outlined
                            v-model="phoneOrgao"
                            :rules="mandatory"
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            class="rounded-lg"
                            label="Logradouro"
                            placeholder="Logradouro"
                            outlined
                            v-model="addressOrgao.public_place"
                            :rules="mandatory"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            class="rounded-lg"
                            label="Bairro"
                            placeholder="Bairro"
                            outlined
                            v-model="addressOrgao.district"
                            :rules="mandatory"
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            class="rounded-lg"
                            label="CEP"
                            placeholder="CEP"
                            v-mask="'#####-###'"
                            outlined
                            v-model="addressOrgao.cep"
                            :rules="mandatory"
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            type="number"
                            min="0"
                            class="rounded-lg"
                            label="Número"
                            placeholder="Número"
                            outlined
                            v-model="addressOrgao.address_number"
                            v-mask="'####'"
                            :rules="numberResidencie"
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-select
                            dense
                            outlined
                            v-model="addressOrgao.country_id"
                            :items="countryListOrgao"
                            :rules="mandatory"
                            item-text="name"
                            item-value="id"
                            label="País"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-select
                            dense
                            outlined
                            v-model="addressOrgao.state_id"
                            :items="stateListOrgao"
                            :rules="mandatory"
                            item-text="name"
                            item-value="id"
                            label="Estado"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-select
                            dense
                            outlined
                            v-model="addressOrgao.county_id"
                            :items="countyListOrgao"
                            :rules="mandatory"
                            item-text="name"
                            item-value="id"
                            label="Cidade"
                          ></v-select>
                        </v-col>
                      </v-row>

                      <v-card-actions class="cardFooter">
                        <v-spacer></v-spacer>

                        <v-btn
                          class="rounded-lg"
                          color="#e74c3c"
                          text
                          elevation="0"
                          @click="dialogOrgao = false"
                        >
                          <span>Cancelar</span>
                        </v-btn>

                        <v-btn
                          class="rounded-lg"
                          elevation="0"
                          color="#27ae60"
                          type="submit"
                        >
                          <span class="textBtn">Criar</span>
                          <v-icon color="#FFFFFF" small class="ml-2"
                            >mdi-arrow-right</v-icon
                          >
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-card>
              <v-snackbar v-model="snackbar">
                {{ message2 }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="green"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialog = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="e1 = 1"> Voltar </v-btn>
                <v-btn
                  color="primary"
                  :disabled="disabledButton"
                  @click="submitForm()"
                >
                  {{ buttom_name }}
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "@/axios";
import AlertError from "../alerts/Error-component.vue";
import AlertError2 from "../alerts/Error2-component.vue";

export default {
  data() {
    return {
      dialog: false,
      dialogOrgao: false,
      disabledButton: false,
      snackbarOrgao: false,
      e1: 1,
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      message: [],
      message2: [],
      disabledCPF: false,
      displayError: {
        display: false,
        message: [],
      },

      displayError2: {
        display2: false,
        message2: [],
      },

      // Route
      genreAllc: [],
      getAllDeficiencyc: [],
      snackbar: false,

      // Cadastro de Orgão
      nameOrgao: "",
      responsibleOrgao: "",
      emailOrgao: "",
      cnpjOrgao: "",
      phoneOrgao: "",
      addressOrgao: {},
      agencyListOrgao: [],
      countryListOrgao: [],
      stateListOrgao: [],
      countyListOrgao: [],
      HealthRegionOrgao: "",
      regional_nucleus_health_idOrgao: "",
      HealthRegionListOrgao: [],
      regionalNucleusHealthListOrgao: [],
      // End

      requestType: "salvar",
      buttom_name: "CADASTRAR",
      commission_id: null,
      // Cadastro
      name: null,
      allFunctionCommission: [],
      socialName: null,
      cpfCommission: "",
      birthDate: null,
      genre: null,
      functionCommision: null,
      breed: null,
      religion: null,
      issuingAgency: null,
      rg: null,
      checkedDeficiency: false,
      selectDeficiency: [],
      tell: "",
      issuer: "",
      email: null,
      sexualitys: [],
      sexuality: null,
      comissionCPF: null,
      id_cpf_edit: null,
      id_rg_edit: null,
      id_email_edit: null,
      id_address_edit: null,
      id_phone_edit: null,

      // Endereço
      publicPlace: null,
      cep: null,
      numberStreet: null,
      municipaly: null,
      residence: null,
      district: null,
      updated: null,
      county_id: null,
      institute_id: null,
      regional_nucleus_health_id: null,

      //Estrutura
      formData: {
        people: {
          birth_date: null,
          name: null,
          sexuality: null,
          social_name: null,
          gender_id: null,
          county_id: null,
          occupation: null,
          religion: null,
          breed_id: null,
          conference_id: null, //Id da conferência vinculada
          deficiency: null,
          agency_id: null,
        },
        address: {
          cep: null,
          county_id: null,
          public_place: null,
          address_number: null,
          district: null,
        },
        phone: [],
        documents: {
          RG: {
            document_number: null,
            issuing_body: null,
          },
          CPF: {
            document_number: null,
          },
        },
        email: {
          name: null,
        },
      },
      // Regras de validação
      mandatory: [(v) => !!v || "Campo obrigatório!"],

      cpfRules: [
        (v) => !!v || "O CPF é obrigatório",
        (v) => (v && v.length <= 14) || "Você excedeu o tamanho máximo.",
      ],

      nameRules: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 60) || "Você excedeu o tamanho máximo.",
      ],

      numberResidencie: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 4) || "Você excedeu o tamanho máximo!",
        (v) => (v && v > 0) || "O número é abaixo do esperado",
      ],

      nameLogradouro: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
      ],

      namesTrick: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
      ],

      emailRules: [
        (v) => !!v || "O campo é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "O e-mail não é válido.",
        (v) => (v && v.length <= 70) || "Você excedeu o tamanho máximo.",
      ],
      permission: null,
      permissionOne: null,
    };
  },

  components: {
    AlertError,
    AlertError2,
  },

  props: {
    origin: {
      type: String,
    },
    resource: {
      type: Object,
    },
  },

  methods: {
    verifyIfConferenceIsEnded() {
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      if (
        this.$store.state.profile_id == 3 ||
        this.$store.state.profile_id == 4
      ) {
        let conference_date = new Date(
          this.$store.state.conference.final_county_phase_date.replace(
            /-/g,
            "/"
          )
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }

      if (this.$store.state.profile_id == 6) {
        let conference_date = new Date(
          this.$store.state.conference.final_macro_phase_date.replace(/-/g, "/")
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }

      if (this.$store.state.profile_id == 8) {
        let conference_date = new Date(
          this.$store.state.conference.final_state_phase_date.replace(/-/g, "/")
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }
    },

    getFunctionCommision() {
      axios.get(`/conference/${this.$route.query.id}/roles`).then((res) => {
        this.allFunctionCommission = res.data;
      });

      this.permission = Number(this.$store.state.finished_release_conference);
      this.profile_id_permission = Number(this.$store.state.profile_id);
    },

    getRgAndCpf(documents) {
      let rg;
      let cpf;
      cpf = documents.filter((x) => x.document_type_id == "1");
      rg = documents.filter((x) => x.document_type_id == "2");
      return { rg: rg, cpf: cpf };
    },

    searchComissionByCpf() {
      const newCPF = this.comissionCPF.replace(/[^a-zA-Z0-9]/g, "");
      axios.post("people/get-comission-by-cpf", { cpf: newCPF }).then((res) => {
        let item = res.data;
        if (!item) {
          return;
        }
        if (typeof item !== "object") {
          this.disabledCPF = false;
        }
        if (typeof item === "object") {
          this.disabledCPF = true;
        }

        this.commission_id = item.id;
        this.requestType = "editar";
        this.buttom_name = "ATUALIZAR";

        let cpf;
        let rg;
        cpf = this.getRgAndCpf(item.documents).cpf[0];
        rg = this.getRgAndCpf(item.documents).rg[0];

        if (rg) {
          this.id_rg_edit = rg.id;
          this.rg = rg.document_number;
          this.issuer = rg.issuing_body;
        }

        this.id_cpf_edit = cpf.id;

        this.cpfCommission = cpf.document_number;

        // Cadastro
        this.name = item.name;
        this.socialName = item.social_name;

        this.birthDate = item.birth_date ? item.birth_date.slice(0, 10) : null;

        if (item.gender) {
          this.genre = item.gender.id;
        }

        if (item.breed) {
          this.breed = item.breed.id;
        }

        this.sexuality = Number(item.sexuality_id);
        this.religion = item.religion;

        if (item.agency) {
          this.issuingAgency = item.agency.id;
        }

        this.checkedDeficiency = Number(item.has_deficiency);
        this.tell = item.phone[0].ddd + item.phone[0].phone_number;
        this.email = item.email[0].name;

        this.selectDeficiency = [];
        for (let i = 0; i < item.deficiency.length; i++) {
          this.selectDeficiency.push(item.deficiency[i].id);
        }

        this.id_email_edit = item.email[0].id;
        this.id_address_edit = item.address[0].id;
        this.id_phone_edit = item.phone[0].id;

        // Endereço
        this.publicPlace = item.address[0].public_place;
        this.cep = item.address[0].cep;
        this.numberStreet = item.address[0].address_number;
        this.municipaly = Number(item.address[0].county_id);
        this.district = item.address[0].district;
        this.dialogEdit = true;
      });
    },

    submitForm() {
      if (!this.$refs.form.validate()) return;
      if (!this.$refs.form2.validate()) return;

      let phone_id;
      phone_id = this.id_phone_edit;
      function removeFirstTwoDigits(phoneNumber) {
        let ddd = phoneNumber.substring(0, 2);
        phoneNumber = phoneNumber.substring(2);
        if (phone_id != null) {
          return { id: phone_id, ddd: ddd, phone_number: phoneNumber };
        }
        return { ddd: ddd, phone_number: phoneNumber };
      }

      //REGEX
      const newCPF = this.cpfCommission.replace(/[^a-zA-Z0-9]/g, "");
      const newPhone = this.tell.replace(/[^\d]/g, "");
      const newRG = this.rg.replace(/[^a-zA-Z0-9]/g, "");
      const newCEP = this.cep.replace(/[^a-zA-Z0-9]/g, "");

      if (this.requestType == "editar") {
        this.formData.people.county_id = this.county_id;
        this.formData.people.institute_id = this.institute_id;
        this.formData.people.regional_nucleus_health_id =
          this.regional_nucleus_health_id;
      }

      // First stap
      this.formData.people.requestType = this.requestType;
      this.formData.documents.CPF.id = this.id_cpf_edit;
      this.formData.documents.RG.id = this.id_rg_edit;
      this.formData.email.id = this.id_email_edit;
      this.formData.address.id = this.id_address_edit;

      this.formData.people.requestType = this.requestType;
      this.formData.people.people_id = this.commission_id;
      this.formData.people.conference_id = this.$route.query.id;
      this.formData.people.agency_id = this.issuingAgency;
      this.formData.people.name = this.name;
      this.formData.people.social_name = this.socialName;
      this.formData.documents.CPF.document_number = newCPF;
      this.formData.documents.RG.issuing_body = this.issuer;
      this.formData.documents.RG.document_number = newRG;
      this.formData.people.religion = this.religion;
      this.formData.people.birth_date = this.birthDate;
      this.formData.people.gender_id = this.genre;
      this.formData.people.breed_id = this.breed;
      this.formData.email.name = this.email;
      this.formData.people.role_id = this.functionCommision;
      this.formData.phone.push(removeFirstTwoDigits(newPhone));
      this.formData.people.sexuality_id = this.sexuality;

      this.formData.people.deficiency =
        this.selectDeficiency.length == 1 ? this.selectDeficiency : null;
      this.formData.people.has_deficiency = this.checkedDeficiency;

      // Endereço
      this.formData.address.cep = newCEP;
      this.formData.address.public_place = this.publicPlace;
      this.formData.address.address_number = this.numberStreet;
      this.formData.address.county_id = this.municipaly;
      this.formData.address.district = this.district;

      if (this.origin == "institute") {
        this.formData.people.institute_id = this.$route.query.institute_id;
      }
      if (this.origin == "county") {
        this.formData.people.county_id = this.$route.query.county_id;
      }
      if (this.origin == "regional_nucleus") {
        this.formData.people.regional_nucleus_health_id =
          this.$route.query.regional_nucleus_health_id;
      }
      if (this.origin == "state") {
        this.formData.people.state_id = this.$route.query.state_id;
      }

      this.disabledButton = true;
      axios
        .post("people/commission", this.formData)
        .then((res) => {
          // this.snackbar = true;
          // this.message2 = res.data.message;
          this.$refs.form2.reset();
          this.$refs.form.reset();
          this.formData.people = {};
          this.displayError2.display2 = false;
          // setTimeout(() => {
          this.disabledButton = false;
          this.e1 = 1;
          this.dialog = false;
          // }, 4000);
          this.$swal({
            icon: "success",
            title: res.data.message,
            text: "Comissão cadastrada!",
          });
          this.comissionCPF = "";
          this.requestType = "salvar";
          this.buttom_name = "CADASTRAR";
          this.disabledCPF = false;
          this.$emit("eventname", "");
        })
        .catch((err) => {
          setTimeout(() => {
            this.displayError2.display2 = false;
            this.disabledButton = false;
          }, 5000);

          if (typeof err === "string") {
            this.displayError2.message2 = err;
            this.displayError2.display2 = true;
          }

          if (typeof err !== "string") {
            if (err.data.message) {
              this.$swal({
                icon: "error",
                title: "Erro ao cadastrar comissão",
                text: err.data.message,
              });
            }

            if (err.data.details) {
              this.$swal({
                icon: "error",
                title: "Erro ao cadastrar comissão",
                text: err.data.details,
              });
            }
          }
        });
    },

    loadSelects() {
      axios.get(`/county`).then((response) => {
        this.countyListOrgao = response.data;
      });

      axios.get(`/country`).then((response) => {
        this.countryListOrgao = response.data;
      });

      axios.get(`/state`).then((response) => {
        this.stateListOrgao = response.data;
      });

      this.updated = this.resource.getAllEntity;

      axios.get(`sexuality`).then((res) => {
        this.sexualitys = res.data;
      });
    },

    resetForm() {
      this.requestType = "salvar";
      (this.buttom_name = "CADASTRAR"), this.$refs.form2.reset();
      this.$refs.form.reset();
      this.formData.people = {};
      this.disabledCPF = false;
    },

    limparCampo() {
      this.comissionCPF = "";
      this.cpfCommission = "";
      this.birthDate = "";
      this.tell = "";
    },

    create() {
      if (this.$refs.form3.validate() == true) {
        const dados = {
          name: this.nameOrgao,
          responsible: this.responsibleOrgao,
          email: this.emailOrgao,
          cnpj: this.cnpjOrgao,
          phone: this.phoneOrgao,
          address: this.addressOrgao,
        };

        axios
          .post(`/agency`, dados)
          .then((response) => {
            if (response.status == 201) {
              this.resource.getAllEntity;
              // this.message = response.data.message;
              // this.snackbarOrgao = true;
              this.resetForm();
              // setTimeout(() => {
              this.dialogOrgao = false;
              // }, 3000);
              this.$swal({
                icon: "success",
                title: "Cadastro realizado com sucesso!",
                text: response.data.message,
              });
              return;
            }
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });
            this.displayError.message = error.toString();
            this.displayError.display = true;
          });
      }
    },

    openModal() {
      this.getFunctionCommision();
      this.loadSelects();
      this.resource.getAllDeficiency;
      this.genreAllc = this.resource.getAllGender;
      this.getAllDeficiencyc = this.resource.getAllDeficiency;
      this.dialog = true;
    },

    validate() {
      this.$refs.form.validate();
    },

    nexTab() {
      if (!this.$refs.form.validate()) return;
      this.e1 = 2;
    },
  },
};
</script>

<style scoped>
.grid-col-ajust {
  grid-column: 1 / span 2 !important;
}

.grid-col-segund {
  grid-column: 3 / span 2 !important;
}

.mtop {
  margin: 0px !important;
}

.bnt-bottom-conf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tab-1-dialog {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 150px 175px auto;
  gap: 8px;
}

.crash {
  grid-column: 1 / 3;
}

.columns-5-3 {
  grid-column: 5 / 3;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.checked {
  margin-top: 5px !important;
}

.banner {
  background: #b3ffab;
  background: -webkit-linear-gradient(to right, #27ae60, #1abc9c);
  background: linear-gradient(to right, #27ae60, #1abc9c);

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.row + .row {
  margin: 0px !important;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>