<template>
  <div class="municipality">
    <v-card class="table-dashboard">
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="itemsCommission"
        :footer-props="{
          'items-per-page-text': 'Registros por Página',
        }"
        :page="page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :server-items-length="TotalResgister"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <div class="header-table">
                  <div v-show="permissionLvl2">
                    <ButtonEntity />
                  </div>
                  <div class="header-space-table">
                    <div v-show="permissionLvl2">
                      <DialogTableCommission
                        :origin="origin"
                        :resource="resource"
                        v-on:eventname="getAllComission()"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </div>
                    <v-btn
                      color="green"
                      class=""
                      dark
                      @click="getAllComission()"
                    >
                      atualizar
                    </v-btn>
                  </div>
                </div>
              </template>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Tem certeza que deseja deletar este item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >cancelar</v-btn
                  >
                  <v-btn color="red darken-1" text @click="deleteItemConfirm"
                    >Deletar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template
          v-slot:item.actions="{ item }"
          v-if="
            $store.state.profile_id != 2 &&
            $store.state.profile_id != 21 &&
            $store.state.profile_id != 41
          "
        >
          <template>
            <v-icon small class="mr-2" @click="editItem(item.commission, item)">
              mdi-pencil
            </v-icon>
          </template>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>

      <v-dialog v-model="dialogEdit" max-width="780">
        <AlertError2
          class="mb-5"
          v-model="displayError2"
          :resource="displayError2"
        />

        <v-card>
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">
                Cadastro
              </v-stepper-step>
              <v-divider> </v-divider>
              <v-stepper-step :complete="e1 > 2" step="2">
                Endereço
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card class="mb-12 px-5 py-5" height="480px">
                  <v-form ref="form" lazy-validation class="tab-1-dialog">
                    <v-text-field
                      label="Nome"
                      :rules="nameRules"
                      :counter="60"
                      v-model="name"
                      id="name"
                      placeholder="Nome*"
                      outlined
                      dense
                      class="input-ajust grid-col-ajust"
                      required
                    ></v-text-field>
                    <v-text-field
                      label="Nome Social"
                      v-model="socialName"
                      :counter="60"
                      id="social"
                      placeholder="Nome Social"
                      outlined
                      dense
                      class="input-ajust grid-col-segund"
                      required
                    ></v-text-field>
                    <v-text-field
                      label="CPF"
                      masked="false"
                      v-mask.raw="'###.###.###-##'"
                      :rules="cpfRules"
                      v-model="cpfCommission"
                      :disabled="true"
                      id="CPF"
                      placeholder="CPF*"
                      outlined
                      dense
                      class="input-ajust crash"
                      required
                    ></v-text-field>
                    <v-text-field
                      label="Data de nascimento"
                      v-model="birthDate"
                      type="date"
                      id="date"
                      placeholder="Data de Nascimento"
                      outlined
                      dense
                      class="input-ajust columns-5-3"
                      required
                    ></v-text-field>
                    <v-text-field
                      label="E-mail*"
                      :counter="70"
                      :rules="emailRules"
                      v-model="email"
                      id="email"
                      type="email"
                      placeholder="E-mail"
                      outlined
                      dense
                      class="input-ajust crash"
                      required
                    ></v-text-field>
                    <v-text-field
                      label="Telefone"
                      :rules="mandatory"
                      v-mask="['(##) ####-#####']"
                      v-model="tell"
                      id="date"
                      placeholder="Telefone"
                      outlined
                      dense
                      class="input-ajust columns-5-3"
                      required
                    ></v-text-field>
                    <v-select
                      :items="resource.getAllGender"
                      item-text="name"
                      item-value="id"
                      id="sex"
                      :rules="mandatory"
                      v-model="genre"
                      label="Gênero"
                      required
                      dense
                      outlined
                    ></v-select>
                    <v-text-field
                      label="Religião"
                      type="text"
                      v-model="religion"
                      id="religion"
                      placeholder="Religião"
                      outlined
                      dense
                      required
                    ></v-text-field>
                    <v-select
                      :items="allFunctionCommission"
                      id="instruction"
                      class="input-ajust columns-5-3"
                      v-model="functionCommision"
                      :rules="mandatory"
                      item-text="name"
                      item-value="id"
                      label="Função Comissão*"
                      required
                      dense
                      outlined
                    ></v-select>
                    <v-select
                      :items="resource.getAllBreed"
                      :rules="mandatory"
                      item-text="name"
                      item-value="id"
                      id="breed"
                      v-model="breed"
                      label="Raça/Cor"
                      required
                      dense
                      outlined
                    ></v-select>
                    <v-select
                      style="max-width: 200px"
                      :items="resource.getAllEntity"
                      item-text="name"
                      :rules="mandatory"
                      item-value="id"
                      id="Ôrgão/Entidade"
                      v-model="issuingAgency"
                      label="Ôrgão/Entidade"
                      required
                      dense
                      outlined
                    ></v-select>

                    <v-text-field
                      label="RG"
                      id="tellResi"
                      :rules="mandatory"
                      v-model="rg"
                      v-mask="['##.###.###-##']"
                      placeholder="RG"
                      outlined
                      dense
                      class="input-ajust"
                      required
                    ></v-text-field>

                    <v-text-field
                      label="Orgão/Emissor"
                      :counter="4"
                      :rules="mandatory"
                      id="tellResi"
                      v-model="issuer"
                      :max="4"
                      placeholder="Ex: SSP"
                      outlined
                      dense
                      class="input-ajust"
                      required
                    ></v-text-field>

                    <v-select
                      :items="sexualitys"
                      item-text="name"
                      :rules="mandatory"
                      item-value="id"
                      v-model="sexuality"
                      label="Sexualidade"
                      dense
                      outlined
                    ></v-select>

                    <v-checkbox
                      v-model="checkedDeficiency"
                      :disabled="true"
                      label="Possui Deficiência?"
                    >
                    </v-checkbox>
                    <v-select
                      v-if="checkedDeficiency"
                      :rules="mandatory"
                      :items="resource.getAllDeficiency"
                      item-text="name"
                      item-value="id"
                      multiple
                      v-model="selectDeficiency"
                      label="Selecione"
                      outlined
                    ></v-select>
                  </v-form>
                </v-card>
                <div class="bnt-bottom-conf">
                  <v-btn text @click="dialogEdit = false"> Cancelar </v-btn>
                  <v-btn color="primary" @click="nexTab()"> Continuar </v-btn>
                </div>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card class="mb-12 px-5 py-5" height="300px">
                  <v-form ref="form2" lazy-validation class="tab-1-dialog">
                    <v-text-field
                      label="Logradouro"
                      type="text"
                      :rules="nameLogradouro"
                      :counter="60"
                      placeholder="Logradouro"
                      v-model="publicPlace"
                      outlined
                      dense
                      class="input-ajust grid-col-ajust"
                      required
                    ></v-text-field>

                    <v-text-field
                      label="CEP"
                      id="cep"
                      :rules="mandatory"
                      placeholder="CEP"
                      v-model="cep"
                      v-mask="'#####-###'"
                      outlined
                      dense
                      class="input-ajust grid-col-segund"
                      required
                    ></v-text-field>

                    <v-text-field
                      label="BAIRRO"
                      v-model="district"
                      placeholder="BAIRRO"
                      :rules="namesTrick"
                      :counter="40"
                      outlined
                      dense
                      class="input-ajust grid-col-ajust"
                      required
                    ></v-text-field>

                    <v-select
                      :items="resource.getAllCounty"
                      item-text="name"
                      item-value="id"
                      v-model="municipaly"
                      :rules="mandatory"
                      class="input-ajust grid-col-segund"
                      label="Municipio"
                      required
                      dense
                      outlined
                    ></v-select>
                    <v-text-field
                      label="NÚMERO"
                      v-model="numberStreet"
                      type="number"
                      id="numberStreet"
                      :counter="3"
                      min="0"
                      placeholder="Número Residência"
                      :rules="numberResidencie"
                      outlined
                      dense
                      class="input-ajust grid-col-ajust"
                      required
                    ></v-text-field>
                  </v-form>
                </v-card>
                <v-snackbar v-model="snackbar">
                  {{ message2 }}
                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="green"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      Fechar
                    </v-btn>
                  </template>
                </v-snackbar>
                <div class="bnt-bottom-conf">
                  <v-btn text @click="dialog = false"> Cancelar </v-btn>
                  <v-btn color="primary" @click="e1 = 1"> Voltar </v-btn>
                  <v-btn color="primary" @click="submitForm()">
                    Finalizar
                  </v-btn>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-dialog>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ message2 }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import DialogTableCommission from "../dialogs/dialog-table-commission.vue";
import AlertError2 from "../alerts/Error2-component.vue";
import ButtonEntity from "../dialogEdit/dialog-launch-all.vue";

export default {
  data: () => ({
    dialog: false,
    dialogEdit: false,
    e1: 1,

    message: [],
    message2: [],

    displayError2: {
      display2: false,
      message2: [],
    },

    sexualitys: [],

    loading: true,
    formTitle: "testando",
    dialogDelete: false,
    headers: [
      { text: "AÇÕES", value: "actions", sortable: false },
      {
        text: "NOME",
        align: "start",
        sortable: false,
        value: "commission.name",
      },
      { text: "MUNICÍPIO", value: "county.name", sortable: false },
      { text: "RAÇA/COR", value: "commission.breed.name", sortable: false },
      { text: "SEXO", value: "commission.gender.name", sortable: false },
      { text: "FUNÇÃO", value: "role.name", sortable: false },
    ],

    itemsCommission: [],

    editedIndex: -1,

    // Route
    genreAllc: [],
    getAllDeficiencyc: [],
    snackbar: false,

    // Cadastro de Orgão
    nameOrgao: "",
    responsibleOrgao: "",
    emailOrgao: "",
    resultQuery: null,
    cnpjOrgao: "",
    phoneOrgao: "",
    addressOrgao: {},
    agencyListOrgao: [],
    countryListOrgao: [],
    stateListOrgao: [],
    countyListOrgao: [],
    HealthRegionOrgao: "",
    regional_nucleus_health_idOrgao: "",
    HealthRegionListOrgao: [],
    regionalNucleusHealthListOrgao: [],
    // End

    page: 1,
    numberOfPages: 0,
    passengers: [],
    options: {},
    sexuality: null,

    permissionLvl2: false,

    // Cadastro
    name: null,
    allFunctionCommission: [],
    socialName: null,
    cpfCommission: "",
    birthDate: null,
    genre: null,
    functionCommision: null,
    breed: null,
    religion: null,
    issuingAgency: null,
    rg: null,
    checkedDeficiency: false,
    selectDeficiency: [],
    tell: "",
    issuer: "",
    id_delete: null,
    email: null,
    id_edit: null,
    id_cpf_edit: null,
    id_rg_edit: null,
    id_email_edit: null,
    id_address_edit: null,
    id_phone_edit: null,
    TotalResgister: null,
    sortBy: null,

    // Endereço
    publicPlace: null,
    cep: null,
    numberStreet: null,
    municipaly: null,
    residence: null,
    district: null,
    people_conference_id: null,
    //Estrutura
    formData: {
      people: {
        birth_date: null,
        sexuality: null,
        name: null,
        county_id: null,
        social_name: null,
        gender_id: null,
        occupation: null,
        religion: null,
        breed_id: null,
        conference_id: null, //Id da conferência vinculada
        deficiency: [],
        agency_id: null,
      },
      address: {
        cep: null,
        county_id: null,
        public_place: null,
        address_number: null,
        district: null,
      },
      phone: [],
      documents: {
        RG: {
          document_number: null,
          issuing_body: null,
        },
        CPF: {
          document_number: null,
        },
      },
      email: {
        name: null,
      },
    },

    mandatory: [(v) => !!v || "Campo obrigatório!"],

    cpfRules: [
      (v) => !!v || "O CPF é obrigatório",
      (v) => (v && v.length <= 14) || "Você excedeu o tamanho máximo.",
    ],

    nameRules: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 60) || "Você excedeu o tamanho máximo.",
    ],

    numberResidencie: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 4) || "Você excedeu o tamanho máximo!",
      (v) => (v && v > 0) || "O número é abaixo do esperado",
    ],

    nameLogradouro: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
    ],

    namesTrick: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
    ],

    emailRules: [
      (v) => !!v || "O campo é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "O e-mail não é válido.",
      (v) => (v && v.length <= 70) || "Você excedeu o tamanho máximo.",
    ],
    permissionOne: null,
    permission: null,
  }),

  props: {
    resource: {
      type: Object,
    },
    origin: {
      type: String,
    },
  },

  components: {
    DialogTableCommission,
    AlertError2,
    ButtonEntity,
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    sortBy: {
      handler() {
        console.log(this.sortBy);
      },
    },
    options: {
      handler() {
        this.getAllComission();
      },
    },
    deep: true,
  },

  created() {
    this.getAllComission();
    this.verification();
  },

  methods: {
    getFunctionCommision() {
      axios.get(`/conference/${this.$route.query.id}/roles`).then((res) => {
        this.allFunctionCommission = res.data;
      });
    },

    loadSelects() {
      axios.get(`/county`).then((response) => {
        this.countyListOrgao = response.data;
      });

      axios.get(`/country`).then((response) => {
        this.countryListOrgao = response.data;
      });

      axios.get(`/state`).then((response) => {
        this.stateListOrgao = response.data;
      });

      axios.get(`sexuality`).then((res) => {
        this.sexualitys = res.data;
      });
    },

    getRgAndCpf(documents) {
      let rg;
      let cpf;
      cpf = documents.filter((x) => x.document_type_id == "1");
      rg = documents.filter((x) => x.document_type_id == "2");
      return { rg: rg, cpf: cpf };
    },

    getAllComission() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;
      let data = new Object();
      data.per_page = this.options.itemsPerPage;

      if (pageNumber == undefined) {
        pageNumber = 1;
      }
      if (data.per_page == undefined) {
        data.per_page = 10;
      }

      if (this.origin == "institute") {
        this.resultQuery = `conference/${
          this.$route.query.id
        }/commission?page=${pageNumber}&per_page=${
          data.per_page
        }&institute_id=${Number(this.$route.query.institute_id)}`;
      }

      if (this.origin == "county") {
        this.resultQuery = `conference/${this.$route.query.id}/commission?page=${pageNumber}&per_page=${data.per_page}&county_id=${this.$route.query.county_id}`;
      }

      if (this.origin == "regional_nucleus") {
        this.resultQuery = `conference/${this.$route.query.id}/commission?page=${pageNumber}&per_page=${data.per_page}&regional_nucleus_health_id=${this.$route.query.regional_nucleus_health_id}`;
      }

      if (this.origin == "state") {
        this.resultQuery = `conference/${this.$route.query.id}/commission?page=${pageNumber}&per_page=${data.per_page}&state_id=${this.$route.query.state_id}`;
      }

      axios.get(this.resultQuery).then((res) => {
        this.loading = false;
        this.itemsCommission = res.data.data;
        this.TotalResgister = res.data.total;
        this.numberOfPages = res.data.last_page;
      });
    },

    editItem(commission, item) {
      this.getFunctionCommision();
      this.loadSelects();

      let cpf;
      let rg;
      cpf = this.getRgAndCpf(commission.documents).cpf[0];
      rg = this.getRgAndCpf(commission.documents).rg[0];

      if (rg) {
        this.id_rg_edit = rg.id;
        this.rg = rg.document_number;
        this.issuer = rg.issuing_body;
      }
      this.people_conference_id = item.id;
      this.id_cpf_edit = cpf.id;
      this.cpfCommission = cpf.document_number;

      // Cadastro
      this.id_edit = commission.id;
      this.name = commission.name;
      this.socialName = commission.social_name;

      this.birthDate = commission.birth_date
        ? commission.birth_date.slice(0, 10)
        : null;
      this.genre = commission.gender.id;
      this.functionCommision = Number(item.role_id);
      this.breed = commission.breed.id;
      this.sexuality = Number(commission.sexuality_id);
      this.religion = commission.religion;

      this.issuingAgency = commission.agency.id;
      this.checkedDeficiency = Number(commission.has_deficiency);
      this.tell = commission.phone[0].ddd + commission.phone[0].phone_number;
      this.email = commission.email[0].name;

      this.selectDeficiency = [];
      for (let i = 0; i < commission.deficiency.length; i++) {
        this.selectDeficiency.push(commission.deficiency[i].id);
      }
      this.id_email_edit = commission.email[0].id;
      this.id_address_edit = commission.address[0].id;
      this.id_phone_edit = commission.phone[0].id;

      // Endereço

      this.publicPlace = commission.address[0].public_place;
      this.cep = commission.address[0].cep;
      this.numberStreet = commission.address[0].address_number;
      this.municipaly = Number(commission.address[0].county_id);
      this.district = commission.address[0].district;
      this.dialogEdit = true;
    },

    submitForm() {
      if (!this.$refs.form.validate()) return;
      if (!this.$refs.form2.validate()) return;

      function removeFirstTwoDigits(phoneNumber, id_phone) {
        let ddd = phoneNumber.substring(0, 2);
        phoneNumber = phoneNumber.substring(2);
        return [{ ddd: ddd, phone_number: phoneNumber, id: id_phone }];
      }

      //REGEX
      const newCPF = this.cpfCommission.replace(/[^a-zA-Z0-9]/g, "");
      const newPhone = this.tell.replace(/[^\d]/g, "");
      const newRG = this.rg.replace(/[^a-zA-Z0-9]/g, "");
      const newCEP = this.cep.replace(/[^a-zA-Z0-9]/g, "");

      this.formData.people.people_conference_id = this.people_conference_id;
      this.formData.people.conference_id = this.$route.query.id;
      this.formData.people.agency_id = this.issuingAgency;
      this.formData.people.name = this.name;
      this.formData.people.social_name = this.socialName;

      if (this.origin == "institute") {
        this.formData.people.institute_id = this.$route.query.institute_id;
      } else if (this.origin == "county") {
        this.formData.people.county_id = this.$route.query.county_id;
      }
      this.formData.documents.CPF.document_number = newCPF;
      this.formData.documents.RG.issuing_body = this.issuer;
      this.formData.documents.RG.document_number = newRG;
      this.formData.people.religion = this.religion;
      this.formData.people.birth_date = this.birthDate;
      this.formData.people.gender_id = this.genre;
      this.formData.people.breed_id = this.breed;
      this.formData.email.name = this.email;
      this.formData.people.roles = this.functionCommision;
      this.formData.phone = removeFirstTwoDigits(newPhone, this.id_phone_edit);

      this.formData.people.deficiency.push(...this.selectDeficiency);
      this.formData.people.has_deficiency = this.checkedDeficiency;

      // Endereço
      this.formData.address.cep = newCEP;
      this.formData.address.public_place = this.publicPlace;
      this.formData.address.address_number = this.numberStreet;
      this.formData.address.county_id = this.municipaly;

      this.formData.address.district = this.district;

      // IDs vinculados
      this.formData.address.id = this.id_address_edit;
      this.formData.documents.CPF.id = this.id_cpf_edit;
      this.formData.documents.RG.id = this.id_rg_edit;
      this.formData.email.id = this.id_email_edit;

      axios
        .put(`people/${this.id_edit}/commission`, this.formData)
        .then((res) => {
          this.snackbar = true;
          this.message2 = res.data.message;
          this.$refs.form2.reset();
          this.$refs.form.reset();
          this.displayError2.display2 = false;
          this.getAllComission();
          setTimeout(() => {
            this.e1 = 1;
            this.dialogEdit = false;
          }, 4000);
        })
        .catch((err) => {
          err;
          var error = "";
          Object.values(this.$store.state.errorMessage).map((value) => {
            error = value;
          });
          this.displayError2.message2 = error.toString();
          this.displayError2.display2 = true;
        });
    },

    deleteItem(item) {
      this.dialogDelete = true;
      this.id_delete = item.id;
    },

    deleteItemConfirm() {
      axios
        .delete(`people-conference/${this.id_delete}`)
        .then((res) => {
          this.getAllComission();
          this.snackbar = true;
          this.message2 = res.data.message;
          this.dialogDelete = false;
        })
        .catch((err) => {
          err;
          var error = "";
          Object.values(this.$store.state.errorMessage).map((value) => {
            error = value;
          });
          this.displayError2.message2 = error.toString();
          this.displayError2.display2 = true;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    verification() {
      if (
        Number(this.$store.state.finished_release_conference) &&
        Number(this.$store.state.restrict_release_conference)
      ) {
        this.permissionLvl2 =
          Number(this.$store.state.profile_id) == 1 ? true : false;
      } else {
        this.permissionLvl2 = true;
      }
    },

    nexTab() {
      if (!this.$refs.form.validate()) return;
      this.e1 = 2;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style scoped>
.delegate-button {
  margin-right: 30px;
  margin-left: 20px;
  display: flex;
  width: 650px;
  justify-content: center;
  align-items: center;
}

.grid-col-ajust {
  grid-column: 1 / span 2 !important;
}

.grid-col-segund {
  grid-column: 3 / span 2 !important;
}

.bnt-bottom-conf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tab-1-dialog {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 20%;
  gap: 8px;
}

.header-table {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-space-table {
  display: flex;
  justify-self: center;
  align-items: center;
  gap: 20px;
  margin: 0 20px;
}

.crash {
  grid-column: 1 / 3;
}

.columns-5-3 {
  grid-column: 5 / 3;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  background: -webkit-linear-gradient(to right, #27ae60, #1abc9c);
  background: linear-gradient(to right, #27ae60, #1abc9c);

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>
