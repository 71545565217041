<template>
  <v-row>
    <v-btn
      color="primary"
      v-if="
        $store.state.profile_id != 2 &&
        $store.state.profile_id != 21 &&
        $store.state.profile_id != 41
      "
      v-show="!verifyIfConferenceIsEnded()"
      dark
      @click.stop="openModal()"
    >
      Cadastrar ANEXOS
    </v-btn>

    <v-dialog v-model="dialog" max-width="780">
      <AlertError2
        class="mb-5"
        v-model="displayError2"
        :resource="displayError2"
      />
      <v-card>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 1" step="1">
              Cadastro de Relatório/Ofícios
            </v-stepper-step>
            <v-divider> </v-divider>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12 px-5 py-5" height="280px">
                <v-form ref="form" lazy-validation class="tab-1-dialog">
                  <v-file-input
                    @change="onFileChange"
                    v-model="image"
                    :rules="mandatory"
                    color="deep-purple accent-4"
                    counter
                    label="Adicione seu arquivo"
                    multiple
                    placeholder="Adicione seu arquivo"
                    prepend-icon="mdi-paperclip"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                </v-form>
              </v-card>
              <v-snackbar v-model="snackbar">
                {{ message }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="green"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialog = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="submitForm()"> CADASTRAR </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "@/axios";
import AlertError2 from "../alerts/Error2-component.vue";

export default {
  data() {
    return {
      dialog: false,
      dialogOrgao: false,
      snackbarOrgao: false,
      e1: 1,
      message: [],
      message2: [],
      items: [],
      files: [],

      displayError2: {
        display2: false,
        message2: [],
      },

      // Route
      genreAllc: [],
      getAllDeficiencyc: [],
      snackbar: false,

      //Imagem
      image: null,
      imageUrl: null,
      archive: {
        archive_name: "",
        archive_extension: "",
        archive_blob: "",
        size: "",
      },

      formRequest: new FormData(),

      // Cadastro
      contents: [],
      content: null,
      eixos: [],
      eixo: null,
      subEixos: [],
      subEixo: null,
      scopes: [],
      scope: null,

      formData: {
        content: null,
        axie_id: null,
        sub_axie_id: null,
        proposal_ambit_id: null,
        conference_id: null,
        county_id: null,
      },
      // Regras de validação
      mandatory: [(v) => !!v || "Campo obrigatório!"],
    };
  },

  components: {
    AlertError2,
  },

  props: {
    resource: {
      type: Object,
    },
    origin: {
      type: String,
    },
  },

  methods: {
    verifyIfConferenceIsEnded() {
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      if (
        this.$store.state.profile_id == 3 ||
        this.$store.state.profile_id == 4
      ) {
        let conference_date = new Date(
          this.$store.state.conference.final_county_phase_date.replace(
            /-/g,
            "/"
          )
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }

      if (this.$store.state.profile_id == 6) {
        let conference_date = new Date(
          this.$store.state.conference.final_macro_phase_date.replace(/-/g, "/")
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }

      if (this.$store.state.profile_id == 8) {
        let conference_date = new Date(
          this.$store.state.conference.final_state_phase_date.replace(/-/g, "/")
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }
    },

    onFileChange() {
      // Transformando IMG -> Blob
      this.imageUrl = URL.createObjectURL(
        new Blob([this.image], { type: "image/jpeg" })
      );
      this.archive.archive_name = this.image.name;
      this.archive.archive_extension = this.image.type;
      this.archive.archive_blob = this.imageUrl;
      this.archive.size = this.image.size;
    },

    submitForm() {
      if (!this.$refs.form.validate()) return;
      this.formRequest.append("archive", this.image[0]);
      this.formRequest.append("conference_id", this.$route.query.id);

      if (this.origin == "institute") {
        this.formRequest.append("institute_id", this.$route.query.institute_id);
      }

      if (this.origin == "county") {
        this.formRequest.append("county_id", this.$route.query.county_id);
      }

      if (this.origin == "regional_nucleus") {
        this.formRequest.append(
          "regional_nucleus_health_id",
          this.$route.query.regional_nucleus_health_id
        );
      }

      if (this.origin == "state") {
        this.formRequest.append("state_id", this.$route.query.state_id);
      }

      const headers = { "Content-Type": "multipart/form-data" };

      axios
        .post("archives", this.formRequest, { headers })
        .then((res) => {
          this.displayError2.display2 = false;
          this.$swal({
            icon: "success",
            title: res.data.message,
            text: "Anexo(s) cadastrado(s)!",
          });
          // this.snackbar = true;
          // this.message = res.data.message;
          this.$refs.form.reset();
          this.$emit("eventname", "");
          // setTimeout(() => {
          this.e1 = 1;
          this.dialog = false;
          // }, 4000);
        })
        .catch((err) => {
          this.formRequest = new FormData();
          if (typeof err === "string") {
            this.$swal({
              icon: "error",
              title: "Erro ao cadastrar anexos",
              text: err,
            });
            return;
          }

          this.$swal({
            icon: "error",
            title: "Erro ao cadastrar anexos",
            text: err.data.details,
          });
        });
    },

    resetForm() {
      this.$refs.form3.reset();
      this.$refs.form3.resetValidation();
    },

    openModal() {
      this.resource.getAllDeficiency;
      this.genreAllc = this.resource.getAllGender;
      this.getAllDeficiencyc = this.resource.getAllDeficiency;
      this.dialog = true;
    },

    validate() {
      this.$refs.form.validate();
    },

    nexTab() {
      if (!this.$refs.form.validate()) return;
      this.e1 = 2;
    },
  },
};
</script>

<style scoped>
.a {
  grid-column: 3/5;
}

.grid-col-ajust2 {
  grid-column: 1 / span 2;
}

.grid-col-segund2 {
  grid-column: 1 / span 2;
}

.bnt-bottom-conf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tab-1-dialog {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
}

.crash2 {
  grid-column: 1 / 3;
}

.grid-button {
  grid-row: 2;
  grid-column: 4;
}

.grid-orgao {
  grid-row: 2;
  grid-column: 3 / span 2;
}

.columns-5-3-2 {
  grid-column: 5 / 3;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  background: -webkit-linear-gradient(to right, #27ae60, #1abc9c);
  background: linear-gradient(to right, #27ae60, #1abc9c);

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.row {
  margin: 0 !important;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>