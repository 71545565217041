import { render, staticRenderFns } from "./dialog-table-commission.vue?vue&type=template&id=68fce269&scoped=true&"
import script from "./dialog-table-commission.vue?vue&type=script&lang=js&"
export * from "./dialog-table-commission.vue?vue&type=script&lang=js&"
import style0 from "./dialog-table-commission.vue?vue&type=style&index=0&id=68fce269&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68fce269",
  null
  
)

export default component.exports