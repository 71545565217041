<template>
  <div class="municipality">
    <v-card class="table-dashboard">
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        class="elevation-1"
        :server-items-length="TotalResgister"
        :options.sync="options"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <div class="delegate-button">
                  <div>
                    <DialogTableProposal
                      :origin="origin"
                      v-show="permissionLvl2"
                      :resource="resource"
                      v-bind="attrs"
                      v-on:eventname="getAll()"
                      v-on="on"
                    />
                    <v-btn color="green" dark @click="getAll()">
                      atualizar
                    </v-btn>
                  </div>
                </div>
              </template>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Tem certeza que deseja deletar este item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >cancelar</v-btn
                  >
                  <v-btn color="red darken-1" text @click="deleteItemConfirm"
                    >Deletar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template
          v-slot:item.actions="{ item }"
          v-if="
            $store.state.profile_id != 2 &&
            $store.state.profile_id != 21 &&
            $store.state.profile_id != 41
          "
        >
          <div style="display: flex; justify-content: start">
            <SwitchButton :resource="item" :TypeOfSwitch="'PROPOSAL_PLENARY'" />
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogEdit" max-width="780">
      <AlertError2
        class="mb-5"
        v-model="displayError2"
        :resource="displayError2"
      />
      <v-card>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 1" step="1">
              Cadastro
            </v-stepper-step>
            <v-divider> </v-divider>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12 px-5 py-5" height="380px">
                <v-form ref="form" lazy-validation class="tab-1-dialog">
                  <v-textarea
                    label="Conteúdo da Proposta"
                    no-resize
                    :rules="nameRules"
                    :counter="700"
                    v-model="content"
                    placeholder="Conteúdo da Proposta*"
                    outlined
                    class="input-ajust grid-col-ajust2"
                    required
                  ></v-textarea>

                  <v-text-field
                    v-if="$route.query.state_id"
                    label="Código"
                    :rules="codeRules"
                    :counter="6"
                    v-model="code"
                    placeholder="Código*"
                    outlined
                    dense
                    required
                  ></v-text-field>

                  <v-select
                    style="max-width: 200px !important"
                    :items="eixos"
                    item-text="title"
                    :rules="mandatory"
                    item-value="id"
                    id="Eixo"
                    v-model="eixo"
                    label="Eixo"
                    required
                    dense
                    outlined
                  ></v-select>

                  <v-select
                    style="max-width: 200px !important"
                    :items="subEixos"
                    item-text="title"
                    item-value="id"
                    id="Sub-Eixo"
                    v-model="subEixo"
                    label="Sub-Eixo"
                    required
                    dense
                    outlined
                  ></v-select>

                  <v-select
                    :items="scopes"
                    item-text="name"
                    :rules="mandatory"
                    item-value="id"
                    id="Âmbito"
                    v-model="scope"
                    label="Âmbito"
                    required
                    dense
                    outlined
                  ></v-select>
                </v-form>
              </v-card>
              <v-snackbar v-model="snackbar">
                {{ message }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="green"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialogEdit = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="submitForm()"> ATUALIZAR </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import SwitchButton from "../switch/SwitchProposalOrGuideline-comp.vue";
import DialogTableProposal from "../dialogs/dialog-table-proposal.vue";
import AlertError2 from "../alerts/Error2-component.vue";

export default {
  data: () => ({
    dialog: false,
    dialogEdit: false,
    dialogDelete: false,
    snackbar: false,
    e1: 1,
    resultQuery: null,
    TotalResgister: null,

    items: [],
    message: [],
    message2: [],

    displayError2: {
      display2: false,
      message2: [],
    },

    page: 1,
    numberOfPages: 0,
    options: {},

    // Cadastro
    contents: [],
    content: null,
    eixos: [],
    eixo: null,
    subEixos: [],
    subEixo: null,
    scopes: [],
    scope: null,
    id_proposal: null,
    code: "",
    formData: {
      content: null,
      axie_id: null,
      sub_axie_id: null,
      proposal_ambit_id: null,
      conference_id: null,
      county_id: null,
    },

    permissionLvl2: false,

    headers: [
      { text: "AÇÕES", value: "actions", sortable: false },
      { text: "Código", value: "code" },
      {
        text: "CONTEÚDO",
        align: "start",
        sortable: false,
        value: "content",
      },
      { text: "EIXO", value: "axie.title", sortable: false },
      { text: "Sub-Eixo", value: "sub_axie.title", sortable: false },
      { text: "ÂMBITO", value: "proposal_ambit.name", sortable: false },
    ],

    // Regras de validação
    mandatory: [(v) => !!v || "Campo obrigatório!"],

    cpfRules: [
      (v) => !!v || "O CPF é obrigatório",
      (v) => (v && v.length <= 14) || "Você excedeu o tamanho máximo.",
    ],

    codeRules: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 6) || "Você excedeu o tamanho máximo!",
    ],

    nameRules: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 700) || "Você excedeu o tamanho máximo.",
    ],

    numberResidencie: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 3) || "Você excedeu o tamanho máximo!",
    ],

    nameLogradouro: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
    ],

    namesTrick: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
    ],

    emailRules: [
      (v) => !!v || "O campo é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "O e-mail não é válido.",
      (v) => (v && v.length <= 70) || "Você excedeu o tamanho máximo.",
    ],
  }),

  props: {
    resource: {
      type: Object,
    },
    origin: {
      type: String,
    },
  },

  components: {
    DialogTableProposal,
    AlertError2,
    SwitchButton,
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    sortBy: {
      handler() {
        console.log(this.sortBy);
      },
    },

    options: {
      handler() {
        this.getAll();
      },
    },

    deep: true,
  },

  created() {
    this.getAll();
    this.verification();
  },

  methods: {
    editItem(item) {
      this.loadSelects();

      this.dialogEdit = true;
      this.code = item.code;
      this.id_proposal = item.id;
      this.content = item.content;
      this.eixo = Number(item.axie_id) == 0 ? null : Number(item.axie_id);
      this.subEixo =
        Number(item.sub_axie_id) == 0 ? null : Number(item.sub_axie_id);
      this.scope = Number(item.proposal_ambit_id)
        ? Number(item.proposal_ambit_id)
        : null;
    },

    verification() {
      if (
        Number(this.$store.state.finished_release_conference) &&
        Number(this.$store.state.restrict_release_conference)
      ) {
        this.permissionLvl2 =
          Number(this.$store.state.profile_id) == 1 ? true : false;
      } else {
        this.permissionLvl2 = true;
      }
    },

    submitForm() {
      if (!this.$refs.form.validate()) return;

      this.formData.code = this.code;
      this.formData.content = this.content;
      this.formData.axie_id = this.eixo;
      this.formData.sub_axie_id = this.subEixo;
      this.formData.proposal_ambit_id = this.scope;
      this.formData.conference_id = this.$route.query.id;

      if (this.origin == "institute") {
        this.formData.institute_id = this.$route.query.institute_id;
      }

      if (this.origin == "county") {
        this.formData.county_id = this.$route.query.county_id;
      }

      if (this.origin == "regional_nucleus") {
        this.formData.regional_nucleus_health_id =
          this.$route.query.regional_nucleus_health_id;
      }

      if (this.origin == "state") {
        this.formData.state_id = this.$route.query.state_id;
      }

      axios
        .put(`proposal/${this.id_proposal}`, this.formData)
        .then((res) => {
          this.displayError2.display2 = false;
          this.snackbar = true;
          this.message = res.data.message;
          this.$refs.form.reset();
          this.getAll();
          setTimeout(() => {
            this.dialogEdit = false;
          }, 2000);
        })
        .catch((err) => {
          this.$swal({
            icon: "error",
            title: "Erro ao lançar propostas",
            text: err.data.details,
          });
        });
    },

    loadSelects() {
      axios.get(`/proposal-ambit`).then((response) => {
        this.scopes = response.data;
      });

      axios.get(`/sub-axie`).then((response) => {
        this.subEixos = response.data;
      });

      axios.get(`/conference/${this.$route.query.id}/axie`).then((response) => {
        this.eixos = response.data;
      });
    },

    getAll() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;
      let data = new Object();
      data.per_page = this.options.itemsPerPage;
      if (pageNumber == undefined) {
        pageNumber = 1;
      }

      if (data.per_page == undefined) {
        data.per_page = 10;
      }

      if (this.origin == "institute") {
        this.resultQuery = `conference/${this.$route.query.id}/proposal?per_page=${data.per_page}&page=${pageNumber}&institute_id=${this.$route.query.institute_id}`;
      }

      if (this.origin == "county") {
        this.resultQuery = `conference/${this.$route.query.id}/proposal?per_page=${data.per_page}&page=${pageNumber}&county_id=${this.$route.query.county_id}`;
      }

      if (this.origin == "regional_nucleus") {
        this.resultQuery = `conference/${this.$route.query.id}/proposal?per_page=${data.per_page}&page=${pageNumber}&regional_nucleus_health_id=${this.$route.query.regional_nucleus_health_id}`;
      }

      if (this.origin == "state") {
        this.resultQuery = `conference/${this.$route.query.id}/proposal?per_page=${data.per_page}&page=${pageNumber}&state_id=${this.$route.query.state_id}`;
      }

      axios.get(this.resultQuery).then((res) => {
        this.items = res.data.data;
        this.loading = false;
        this.TotalResgister = res.data.total;
        this.numberOfPages = res.data.last_page;
      });
    },

    deleteItem(item) {
      this.id_proposal = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      axios
        .delete(`proposal/${this.id_proposal}`)
        .then((res) => {
          this.getAll();
          this.snackbar = true;
          this.message2 = res.data.message;
          this.dialogDelete = false;
        })
        .catch((err) => {
          err;
          var error = "";
          Object.values(this.$store.state.errorMessage).map((value) => {
            error = value;
          });
          this.displayError2.message2 = error.toString();
          this.displayError2.display2 = true;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
<style scoped>
.delegate-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: right !important;
  margin: 0 20px;
}

.delegate-button div {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.a {
  grid-column: 3/5;
}

.grid-col-ajust2 {
  grid-column: 1 / span 2;
}

.grid-col-segund2 {
  grid-column: 1 / span 2;
}

.bnt-bottom-conf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tab-1-dialog {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 459px 238px;
  gap: 8px;
}

.crash2 {
  grid-column: 1 / 3;
}

.grid-button {
  grid-row: 2;
  grid-column: 4;
}

.grid-orgao {
  grid-row: 2;
  grid-column: 3 / span 2;
}

.columns-5-3-2 {
  grid-column: 5 / 3;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  background: -webkit-linear-gradient(to right, #27ae60, #1abc9c);
  background: linear-gradient(to right, #27ae60, #1abc9c);

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>