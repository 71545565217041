<template>
  <v-row>
    <v-btn
      color="primary"
      v-if="
        $store.state.profile_id != 2 &&
        $store.state.profile_id != 21 &&
        $store.state.profile_id != 41
      "
      dark
      @click.stop="openModal()"
      v-show="!verifyIfConferenceIsEnded()"
    >
      Cadastrar observação
    </v-btn>
    <v-dialog v-model="dialog" max-width="780">
      <AlertError2
        class="mb-5"
        v-model="displayError2"
        :resource="displayError2"
      />
      <v-card>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 1" step="1">
              Cadastro
            </v-stepper-step>
            <v-divider> </v-divider>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12 px-5 py-5" height="280px">
                <v-form ref="form" lazy-validation class="tab-2-dialog">
                  <v-textarea
                    v-model="observation"
                    :rules="mandatory"
                    no-resize
                    solo
                    class="textareamain"
                    name="input-7-4"
                    label="Observação"
                  ></v-textarea>
                </v-form>
              </v-card>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialog = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="submitForm()"> FINALIZAR </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "@/axios";
import AlertError2 from "../alerts/Error2-component.vue";

export default {
  data() {
    return {
      dialog: false,
      dialogOrgao: false,
      snackbarOrgao: false,
      e1: 1,
      message: [],
      message2: [],
      items: [],

      displayError2: {
        display2: false,
        message2: [],
      },

      // Route
      genreAllc: [],
      getAllDeficiencyc: [],
      snackbar: false,

      // Cadastro
      observation: null,

      formData: {
        observation: null,
        conference_id: null,
        county_id: null,
      },
      // Regras de validação
      mandatory: [(v) => !!v || "Campo obrigatório!"],
    };
  },

  components: {
    AlertError2,
  },

  props: {
    resource: {
      type: Object,
    },
    origin: {
      type: String,
    },
  },

  methods: {
    verifyIfConferenceIsEnded() {
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      if (
        this.$store.state.profile_id == 3 ||
        this.$store.state.profile_id == 4
      ) {
        let conference_date = new Date(
          this.$store.state.conference.final_county_phase_date.replace(
            /-/g,
            "/"
          )
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }

      if (this.$store.state.profile_id == 6) {
        let conference_date = new Date(
          this.$store.state.conference.final_macro_phase_date.replace(/-/g, "/")
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }

      if (this.$store.state.profile_id == 8) {
        let conference_date = new Date(
          this.$store.state.conference.final_state_phase_date.replace(/-/g, "/")
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }
    },
    submitForm() {
      if (!this.$refs.form.validate()) return;

      this.formData.observation = this.observation;
      this.formData.conference_id = this.$route.query.id;

      if (this.origin == "institute") {
        this.formData.institute_id = this.$route.query.institute_id;
      }

      if (this.origin == "county") {
        this.formData.county_id = this.$route.query.county_id;
      }

      if (this.origin == "regional_nucleus") {
        this.formData.regional_nucleus_health_id =
          this.$route.query.regional_nucleus_health_id;
      }
      
      if (this.origin == "state") {
        this.formData.state_id = this.$route.query.state_id;
      }

      axios
        .post("observation", this.formData)
        .then((res) => {
          this.displayError2.display2 = false;
          // this.snackbar = true;
          this.message = res.data.message;
          this.$refs.form.reset();
          this.e1 = 1;
          this.dialog = false;
          this.$swal({
            icon: "success",
            title: res.data.message,
            text: "Observação cadastrada!",
          }).then(() => {
            this.$emit("refreshGrid", true);
          });
        })
        .catch((err) => {
          this.$swal({
            icon: "error",
            title: "Erro ao cadastrar observação",
            text: err.data.details,
          });
        });
    },

    resetForm() {
      this.$refs.form3.reset();
      this.$refs.form3.resetValidation();
    },

    openModal() {
      this.resource.getAllDeficiency;
      this.genreAllc = this.resource.getAllGender;
      this.getAllDeficiencyc = this.resource.getAllDeficiency;
      this.dialog = true;
    },

    validate() {
      this.$refs.form.validate();
    },

    nexTab() {
      if (!this.$refs.form.validate()) return;
      this.e1 = 2;
    },
  },
};
</script>

<style scoped>
.textareamain {
  width: 100%;
  height: 100%;
}

.row {
  margin: 0 !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  background: -webkit-linear-gradient(to right, #27ae60, #1abc9c);
  background: linear-gradient(to right, #27ae60, #1abc9c);

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>