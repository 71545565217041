<template>
  <v-row class="test">
    <v-btn color="primary" dark @click.stop="openModal()" v-if="$store.state.profile_id != 21 && $store.state.profile_id != 41">
      Cadastrar ORGÃO/ENTIDADE
    </v-btn>

    <v-dialog v-model="dialog" width="600px" persistent>
      <v-card class="create-user-dialog rounded-lg">
        <div class="banner">
          <h4>Criar Orgão Entidade</h4>
        </div>

        <AlertError
          class="mb-5"
          v-model="displayError"
          :resource="displayError"
        />

        <v-form @submit.prevent="create" ref="form">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                class="rounded-lg"
                label="Nome da Orgão/entidade"
                placeholder="Orgão Entidade"
                outlined
                v-model="name"
                :rules="nameRules"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="rounded-lg"
                label="Responsável/Gestor"
                placeholder="Responsável/Gestor"
                outlined
                v-model="responsible"
                :rules="nameRules"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                type="email"
                class="rounded-lg"
                label="Email"
                placeholder="Email"
                outlined
                v-model="email"
                :rules="nameRules"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                class="rounded-lg"
                label="CNPJ"
                placeholder="CNPJ"
                outlined
                v-model="cnpj"
                v-mask="'##.###.###/####-##'"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                class="rounded-lg"
                label="Telefone"
                placeholder="Telefone"
                v-mask="'(##) #####-####'"
                outlined
                v-model="phone"
                :rules="nameRules"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                class="rounded-lg"
                label="Logradouro"
                placeholder="Logradouro"
                outlined
                v-model="address.public_place"
                :rules="nameRules"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="rounded-lg"
                label="Bairro"
                placeholder="Bairro"
                outlined
                v-model="address.district"
                :rules="nameRules"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                class="rounded-lg"
                label="CEP"
                placeholder="CEP"
                v-mask="'#####-###'"
                outlined
                v-model="address.cep"
                :rules="nameRules"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                class="rounded-lg"
                label="Número"
                placeholder="Número"
                outlined
                v-model="address.address_number"
                v-mask="'####'"
                :rules="numberResidencie"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                dense
                outlined
                v-model="address.country_id"
                :items="countryList"
                :rules="nameRules"
                item-text="name"
                item-value="id"
                label="País"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                dense
                outlined
                v-model="address.state_id"
                :items="stateList"
                :rules="nameRules"
                item-text="name"
                item-value="id"
                label="Estado"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                dense
                outlined
                v-model="address.county_id"
                :items="countyList"
                :rules="nameRules"
                item-text="name"
                item-value="id"
                label="Cidade"
              ></v-select>
            </v-col>
          </v-row>

          <v-card-actions class="cardFooter">
            <v-spacer></v-spacer>

            <v-btn
              class="rounded-lg"
              color="#e74c3c"
              text
              elevation="0"
              @click="dialog = false"
            >
              <span>Cancelar</span>
            </v-btn>

            <v-btn
              class="rounded-lg"
              elevation="0"
              color="#27ae60"
              type="submit"
            >
              <span class="textBtn">Enviar dados</span>
              <v-icon color="#FFFFFF" small class="ml-2"
                >mdi-arrow-right</v-icon
              >
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "@/axios";
import AlertError from "../alerts/Error-component.vue";

export default {
  data() {
    return {
      dialog: false,
      entity: "",
      search1: "",
      search2: "",
      search3: "",
      headers: [
        { text: "Ações", value: "actions", sortable: false, width: "20%" },
        {
          text: "Orgão Entidade",
          align: "start",
          value: "name",
          sortable: false,
        },
        {
          text: "CNPJ",
          align: "start",
          value: "cnpj",
          sortable: false,
        },
        {
          text: "Email",
          align: "start",
          value: "email",
          sortable: false,
        },
        {
          text: "Responsável",
          align: "start",
          value: "responsible",
          sortable: false,
        },
        {
          text: "Telefone",
          align: "start",
          value: "phone",
          sortable: false,
        },
        {
          text: "Cep",
          align: "start",
          value: "cep",
          sortable: false,
        },
        {
          text: "Logradouro",
          align: "start",
          value: "public_place",
          sortable: false,
        },
        {
          text: "Bairro",
          align: "start",
          value: "district",
          sortable: false,
        },
        {
          text: "Estado",
          align: "start",
          value: "state",
          sortable: false,
        },
        {
          text: "Cidade",
          align: "start",
          value: "county",
          sortable: false,
        },
      ],

      nameRules: [(v) => !!v || "O campo é obrigatório"],
      numberResidencie: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 4) || "Você excedeu o tamanho máximo!",
        (v) => (v && v > 0) || "O número é abaixo do esperado",
      ],

      displayError: {
        display: false,
        message: [],
      },

      page: 1,
      numberOfPages: 0,
      passengers: [],
      loading: true,
      options: {},
      sortBy: "name",

      TotalResgister: 0,

      message: "",
      snackbar: false,
      loadingBTN: false,
      exibirMensagem: false,
      messageError: "",
      name: "",
      responsible: "",
      email: "",
      cnpj: "",
      phone: "",
      address: {},
      agencyList: [],
      countryList: [],
      stateList: [],
      countyList: [],
      HealthRegion: "",
      regional_nucleus_health_id: "",
      HealthRegionList: [],
      regionalNucleusHealthList: [],
    };
  },

  components: {
    AlertError,
  },

  props: {
    resource: {
      type: Object,
    },
  },

  watch: {
    sortBy: {
      handler() {
        console.log(this.sortBy);
      },
    },
    options: {
      handler() {
        this.indoDataTable();
      },
    },
    deep: true,
  },

  mounted() {
    this.loadSelects();
  },

  methods: {
    loadSelects() {
      axios.get(`/county`).then((response) => {
        this.countyList = response.data;
      });

      axios.get(`/country`).then((response) => {
        this.countryList = response.data;
      });

      axios.get(`/state`).then((response) => {
        this.stateList = response.data;
      });

      axios.get(`/health-region`).then((response) => {
        this.HealthRegionList = response.data;
      });

      axios.get(`/regional-nucleus-health/`).then((response) => {
        this.regionalNucleusHealthList = response.data;
      });
    },

    openModal() {
      this.dialog = true;
    },

    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    create() {
      if (this.$refs.form.validate() == true) {
        const dados = new Object();
        dados.name = this.name;
        dados.responsible = this.responsible;
        dados.email = this.email;
        dados.cnpj = this.cnpj;
        dados.phone = this.phone;
        dados.address = this.address;

        axios
          .post(`/agency`, dados)
          .then((response) => {
            this.message = response.data.message;
            this.snackbar = true;
            this.dialog = false;
            location.reload();
            this.resetForm();
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          });
      }
    },
  },
};
</script>

<style scoped>
.grid-col-ajust {
  grid-column: 1 / span 2 !important;
}

.grid-col-segund {
  grid-column: 3 / span 2 !important;
}

.bnt-bottom-conf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tab-1-dialog {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 8px;
}

.crash {
  grid-column: 1 / 3;
}

.columns-5-3 {
  grid-column: 5 / 3;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  background: -webkit-linear-gradient(to right, #27ae60, #1abc9c);
  background: linear-gradient(to right, #27ae60, #1abc9c);

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.test {
  margin-left: 20px !important;
}

.cardFooter {
  padding: 0 !important;
}
</style>