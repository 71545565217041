<template>
  <v-row>
    <v-btn
      color="primary"
      v-show="!verifyIfConferenceIsEnded()"
      v-if="
        $store.state.profile_id != 2 &&
        $store.state.profile_id != 21 &&
        $store.state.profile_id != 41
      "
      dark
      @click.stop="openModal()"
    >
      Cadastrar DIRETRIZES
    </v-btn>

    <v-dialog v-model="dialog" max-width="780">
      <AlertError2
        class="mb-5"
        v-model="displayError2"
        :resource="displayError2"
      />
      <v-card>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 1" step="1">
              Cadastro da Diretriz
            </v-stepper-step>
            <v-divider> </v-divider>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12 px-5 py-5" height="400px">
                <v-form ref="form" lazy-validation class="tab-2-dialog">
                  <v-row>
                    <v-col cols="12" md="6" v-if="$route.query.state_id">
                      <v-text-field
                        label="Código"
                        :rules="codeRules"
                        :counter="6"
                        v-model="code"
                        placeholder="Código*"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-select
                        :items="eixos"
                        item-text="title"
                        :rules="mandatory"
                        item-value="id"
                        id="Eixo"
                        v-model="eixo"
                        label="Eixo"
                        required
                        dense
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-textarea
                        no-resize
                        full-width
                        label="Conteúdo da Diretriz"
                        :rules="nameRules"
                        :counter="350"
                        v-model="content"
                        class="areatext"
                        placeholder="Conteúdo da Diretriz*"
                        outlined
                        required
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
              <v-snackbar v-model="snackbar">
                {{ message }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="green"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialog = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="submitForm()"> CADASTRAR </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "@/axios";
import AlertError2 from "../alerts/Error2-component.vue";

export default {
  data() {
    return {
      code: "",
      dialog: false,
      e1: 1,
      message: [],
      message2: [],
      items: [],
      eixos: [],

      displayError2: {
        display2: false,
        message2: [],
      },

      // Route
      snackbar: false,

      // Cadastro
      content: null,
      eixo: null,

      formData: {
        content: null,
        county_id: null,
        axie_id: null,
        conference_id: null,
      },
      // Regras de validação
      mandatory: [(v) => !!v || "Campo obrigatório!"],

      codeRules: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 6) || "Você excedeu o tamanho máximo!",
      ],

      cpfRules: [
        (v) => !!v || "O CPF é obrigatório",
        (v) => (v && v.length <= 14) || "Você excedeu o tamanho máximo.",
      ],

      nameRules: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 350) || "Você excedeu o tamanho máximo.",
      ],

      numberResidencie: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 3) || "Você excedeu o tamanho máximo!",
      ],

      nameLogradouro: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
      ],

      namesTrick: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
      ],

      emailRules: [
        (v) => !!v || "O campo é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "O e-mail não é válido.",
        (v) => (v && v.length <= 70) || "Você excedeu o tamanho máximo.",
      ],
    };
  },

  components: {
    AlertError2,
  },

  props: {
    resource: {
      type: Object,
    },
    origin: {
      type: String,
    },
  },

  mounted() {
    this.loadSelects();
  },

  methods: {
    verifyIfConferenceIsEnded() {
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      if (
        this.$store.state.profile_id == 3 ||
        this.$store.state.profile_id == 4
      ) {
        let conference_date = new Date(
          this.$store.state.conference.final_county_phase_date.replace(
            /-/g,
            "/"
          )
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }

      if (this.$store.state.profile_id == 6) {
        let conference_date = new Date(
          this.$store.state.conference.final_macro_phase_date.replace(/-/g, "/")
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }

      if (this.$store.state.profile_id == 8) {
        let conference_date = new Date(
          this.$store.state.conference.final_state_phase_date.replace(/-/g, "/")
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }
    },

    submitForm() {
      if (!this.$refs.form.validate()) return;

      if (this.origin == "institute") {
        const complete = {
          guidelines: [
            {
              name: this.content,
              axie_id: this.eixo,
              conference_id: this.$route.query.id,
              institute_id: this.$route.query.institute_id,
            },
          ],
        };

        this.formData = complete;
      }

      if (this.origin == "county") {
        const complete = {
          guidelines: [
            {
              name: this.content,
              axie_id: this.eixo,
              conference_id: this.$route.query.id,
              county_id: this.$route.query.county_id,
            },
          ],
        };
        this.formData = complete;
      }

      if (this.origin == "regional_nucleus") {
        const complete = {
          guidelines: [
            {
              name: this.content,
              axie_id: this.eixo,
              conference_id: this.$route.query.id,
              regional_nucleus_health_id:
                this.$route.query.regional_nucleus_health_id,
            },
          ],
        };

        this.formData = complete;
      }
      
      if (this.origin == "state") {
        const complete = {
          guidelines: [
            {
              code: this.code,
              name: this.content,
              axie_id: this.eixo,
              conference_id: this.$route.query.id,
              state_id: this.$route.query.state_id,
            },
          ],
        };

        this.formData = complete;
      }

      axios
        .post(`guideline`, this.formData)
        .then((res) => {
          this.displayError2.display2 = false;
          // this.snackbar = true;
          // this.message = res.data.message;
          this.$swal({
            icon: "success",
            title: res.data.message,
            text: "Diretriz cadastrada!",
          });
          this.$refs.form.reset();
          this.$emit("eventname", "");
          // setTimeout(() => {
          this.e1 = 1;
          this.dialog = false;
          // }, 4000);
        })
        .catch((err) => {
          this.$swal({
            icon: "error",
            title: "Erro ao lançar diretrizes",
            text: err.data.details,
          });
        });
    },

    loadSelects() {
      axios.get(`/conference/${this.$route.query.id}/axie`).then((response) => {
        this.eixos = response.data;
      });
    },

    resetForm() {
      this.$refs.form3.reset();
      this.$refs.form3.resetValidation();
    },

    openModal() {
      this.resource.getAllDeficiency;
      this.dialog = true;
    },

    validate() {
      this.$refs.form.validate();
    },

    nexTab() {
      if (!this.$refs.form.validate()) return;
      this.e1 = 2;
    },
  },
};
</script>

<style scoped>
.bnt-bottom-conf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.row {
  margin: 0 !important;
}

.tab-2-dialog {
  height: 100%;
  width: 100%;
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.areatext {
  width: 480px;
}
</style>