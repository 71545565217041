<template>
  <v-row>
    <v-btn
      color="primary"
      v-if="
        $store.state.profile_id != 2 &&
        $store.state.profile_id != 21 &&
        $store.state.profile_id != 41
      "
      v-show="!verifyIfConferenceIsEnded()"
      dark
      @click.stop="openModal()"
    >
      Cadastrar PROPOSTAS
    </v-btn>

    <v-dialog v-model="dialog" max-width="780">
      <AlertError2
        class="mb-5"
        v-model="displayError2"
        :resource="displayError2"
      />
      <v-card>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 1" step="1">
              Cadastro
            </v-stepper-step>
            <v-divider> </v-divider>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row
                v-if="
                  this.$store.state.profile_id == 1 ||
                  this.$store.state.profile_id == 2 ||
                  this.$store.state.profile_id == 6 ||
                  this.$store.state.profile_id == 8
                "
              >
                <v-col cols="12">
                  <v-autocomplete
                    loader-height=""
                    clearable
                    dense
                    outlined
                    @change="getProposalById()"
                    label="Buscar proposta"
                    :items="proposals"
                    item-text="content"
                    item-value="id"
                    class="input-ajust grid-col-ajust2"
                    v-model="proposal_id"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-card class="mb-12 px-5 py-5" height="380px">
                <v-form ref="form" lazy-validation class="tab-1-dialog">
                  <v-textarea
                    label="Conteúdo da Proposta"
                    no-resize
                    :rules="nameRules"
                    :counter="700"
                    v-model="content"
                    placeholder="Conteúdo da Proposta*"
                    outlined
                    class="input-ajust grid-col-ajust2"
                    required
                  ></v-textarea>
                  <v-text-field
                    v-if="$route.query.state_id"
                    label="Código"
                    :rules="codeRules"
                    :counter="6"
                    v-model="code"
                    placeholder="Código*"
                    outlined
                    dense
                    required
                  ></v-text-field>
                  <v-select
                    style="max-width: 200px !important"
                    :items="eixos"
                    item-text="title"
                    :rules="mandatory"
                    item-value="id"
                    id="Eixo"
                    v-model="eixo"
                    label="Eixo"
                    required
                    dense
                    outlined
                  ></v-select>

                  <v-select
                    style="max-width: 200px !important"
                    :items="subEixos"
                    item-text="title"
                    item-value="id"
                    id="Sub-Eixo"
                    v-model="subEixo"
                    label="Sub-Eixo"
                    required
                    dense
                    outlined
                  ></v-select>

                  <v-select
                    :items="scopes"
                    item-text="name"
                    :rules="mandatory"
                    item-value="id"
                    id="Âmbito"
                    v-model="scope"
                    label="Âmbito"
                    required
                    dense
                    outlined
                  ></v-select>
                </v-form>
              </v-card>
              <v-snackbar v-model="snackbar">
                {{ message }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="green"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialog = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="submitForm()"> CADASTRAR </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "@/axios";
import AlertError2 from "../alerts/Error2-component.vue";

export default {
  watch: {
    eixo(value) {
      this.loadSubAxie(value);
    },
  },
  data() {
    return {
      dialog: false,
      e1: 1,
      message: [],
      message2: [],
      items: [],
      proposal_id: null,
      proposals: [],

      displayError2: {
        display2: false,
        message2: [],
      },

      // Route
      genreAllc: [],
      getAllDeficiencyc: [],
      snackbar: false,
      code: "",

      // Cadastro
      contents: [],
      content: null,
      eixos: [],
      eixo: null,
      subEixos: [],
      subEixo: null,
      scopes: [],
      scope: null,

      formData: {
        content: null,
        axie_id: null,
        sub_axie_id: null,
        proposal_ambit_id: null,
        conference_id: null,
        county_id: null,
      },
      // Regras de validação
      mandatory: [(v) => !!v || "Campo obrigatório!"],

      cpfRules: [
        (v) => !!v || "O CPF é obrigatório",
        (v) => (v && v.length <= 14) || "Você excedeu o tamanho máximo.",
      ],

      codeRules: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 6) || "Você excedeu o tamanho máximo!",
      ],

      nameRules: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 700) || "Você excedeu o tamanho máximo.",
      ],

      numberResidencie: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 3) || "Você excedeu o tamanho máximo!",
      ],

      nameLogradouro: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
      ],

      namesTrick: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
      ],

      emailRules: [
        (v) => !!v || "O campo é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "O e-mail não é válido.",
        (v) => (v && v.length <= 70) || "Você excedeu o tamanho máximo.",
      ],
    };
  },

  components: {
    AlertError2,
  },

  props: {
    resource: {
      type: Object,
    },
    origin: {
      type: String,
    },
  },

  methods: {
    verifyIfConferenceIsEnded() {
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      if (
        this.$store.state.profile_id == 3 ||
        this.$store.state.profile_id == 4
      ) {
        let conference_date = new Date(
          this.$store.state.conference.final_county_phase_date.replace(
            /-/g,
            "/"
          )
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }

      if (this.$store.state.profile_id == 6) {
        let conference_date = new Date(
          this.$store.state.conference.final_macro_phase_date.replace(/-/g, "/")
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }

      if (this.$store.state.profile_id == 8) {
        let conference_date = new Date(
          this.$store.state.conference.final_state_phase_date.replace(/-/g, "/")
        );
        if (currentDate > conference_date) {
          return true;
        }
        return false;
      }
    },
    loadSubAxie(axie_id) {
      if (axie_id) {
        axios.get(`/axies/${axie_id}/sub-axies`).then((response) => {
          this.subEixos = response.data;
        });
      }
    },
    getProposals() {
      if (
        this.$store.state.profile_id == 1 ||
        this.$store.state.profile_id == 2 ||
        this.$store.state.profile_id == 6 ||
        this.$store.state.profile_id == 8
      ) {
        axios
          .get(
            `/proposal/conference?conference_id=${this.$route.query.id}&regional_nucleus_health_id=${this.$route.query.regional_nucleus_health_id}&state_id=${this.$route.query.state_id}`
          )
          .then((res) => {
            this.proposals = res.data;
          });
      }
    },

    getProposalById() {
      axios.get(`proposal/${this.proposal_id}`, this.formData).then((res) => {
        let item = res.data;
        this.id_proposal = item.id;
        this.content = item.content;
        this.eixo = Number(item.axie_id) == 0 ? null : Number(item.axie_id);
        this.subEixo =
          Number(item.sub_axie_id) == 0 ? null : Number(item.sub_axie_id);
        this.scope = Number(item.proposal_ambit_id)
          ? Number(item.proposal_ambit_id)
          : null;
      });
    },

    submitForm() {
      if (!this.$refs.form.validate()) return;

      this.formData.content = this.content;
      this.formData.code = this.code;
      this.formData.axie_id = this.eixo;
      this.formData.sub_axie_id = this.subEixo;
      this.formData.proposal_ambit_id = this.scope;
      this.formData.conference_id = this.$route.query.id;

      if (this.origin == "institute") {
        this.formData.institute_id = this.$route.query.institute_id;
      }

      if (this.origin == "county") {
        this.formData.county_id = this.$route.query.county_id;
      }

      if (this.origin == "regional_nucleus") {
        this.formData.regional_nucleus_health_id =
          this.$route.query.regional_nucleus_health_id;
      }

      if (this.origin == "state") {
        this.formData.state_id = this.$route.query.state_id;
      }

      axios
        .post("proposal", this.formData)
        .then((res) => {
          this.displayError2.display2 = false;
          this.$swal({
            icon: "success",
            title: res.data.message,
            text: "Proposta cadastrada!",
          });
          // this.snackbar = true;
          // this.message = res.data.message;
          this.$refs.form.reset();
          this.$emit("eventname", "");
          // setTimeout(() => {
          this.e1 = 1;
          this.dialog = false;
          // }, 4000);
        })
        .catch((err) => {
          this.$swal({
            icon: "error",
            title: "Erro ao lançar propostas",
            text: err.data.details,
          });
          //err;
          //var error = null;
          //Object.values(this.$store.state.errorMessage).map((value) => {
          //  error = value;
          //});
          //this.snackbar = true
          //this.message =error.toString();
          //this.displayError2.message2 = error.toString();
          //this.displayError2.display2 = true;
        });
    },

    loadSelects() {
      this.getProposals();

      axios.get(`/conference/${this.$route.query.id}/axie`).then((response) => {
        this.eixos = response.data;
      });

      axios.get(`/proposal-ambit`).then((response) => {
        this.scopes = response.data;
      });
    },

    resetForm() {
      this.$refs.form3.reset();
      this.$refs.form3.resetValidation();
    },

    openModal() {
      this.loadSelects();
      this.resource.getAllDeficiency;
      this.genreAllc = this.resource.getAllGender;
      this.getAllDeficiencyc = this.resource.getAllDeficiency;
      this.dialog = true;
    },

    validate() {
      this.$refs.form.validate();
    },

    nexTab() {
      if (!this.$refs.form.validate()) return;
      this.e1 = 2;
    },
  },
};
</script>

<style scoped>
.a {
  grid-column: 3/5;
}

.grid-col-ajust2 {
  grid-column: 1 / span 2;
}

.grid-col-segund2 {
  grid-column: 1 / span 2;
}

.bnt-bottom-conf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tab-1-dialog {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
}

.crash2 {
  grid-column: 1 / 3;
}

.grid-button {
  grid-row: 2;
  grid-column: 4;
}

.grid-orgao {
  grid-row: 2;
  grid-column: 3 / span 2;
}

.columns-5-3-2 {
  grid-column: 5 / 3;
}

.row {
  margin: 0;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  background: -webkit-linear-gradient(to right, #27ae60, #1abc9c);
  background: linear-gradient(to right, #27ae60, #1abc9c);

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>