<template>
  <div class="municipality">
    <v-card class="table-dashboard">
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :page="page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :server-items-length="TotalResgister"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <div class="delegate-button">
                  <div>
                    <DialogTable
                      :origin="origin"
                      v-show="permissionLvl2"
                      :resource="resource"
                      v-on:eventname="getAll()"
                      v-bind="attrs"
                      v-on="on"
                    />
                    <v-btn color="green" dark @click="getAll()">
                      atualizar
                    </v-btn>
                  </div>
                </div>
              </template>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Tem certeza que deseja deletar este item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >cancelar</v-btn
                  >
                  <v-btn color="red darken-1" text @click="deleteItemConfirm"
                    >Deletar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template
          v-slot:item.actions="{ item }"
          v-if="
            $store.state.profile_id != 2 &&
            $store.state.profile_id != 21 &&
            $store.state.profile_id != 41
          "
        >
          <div style="display: flex; justify-content: start">
            <SwitchButton
              :resource="item"
              :TypeOfSwitch="'GUIDELINE_PLENARY'"
            />
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogEdit" max-width="780">
      <AlertError2
        class="mb-5"
        v-model="displayError2"
        :resource="displayError2"
      />
      <v-card>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 1" step="1">
              Cadastro Da Diretriz
            </v-stepper-step>
            <v-divider> </v-divider>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12 px-5 py-5" height="400px">
                <v-form ref="form" lazy-validation class="tab-2-dialog">
                  <v-row>
                    <v-col cols="12" md="6" v-if="$route.query.state_id">
                      <v-text-field
                        label="Código"
                        :rules="codeRules"
                        :counter="6"
                        v-model="code"
                        placeholder="Código*"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-select
                        :items="eixos"
                        item-text="title"
                        :rules="mandatory"
                        item-value="id"
                        id="Eixo"
                        v-model="eixo"
                        label="Eixo"
                        required
                        dense
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-textarea
                        no-resize
                        full-width
                        label="Conteúdo da Diretriz"
                        :rules="nameRules"
                        :counter="350"
                        v-model="content"
                        class="areatext"
                        placeholder="Conteúdo da Diretriz*"
                        outlined
                        required
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
              <v-snackbar v-model="snackbar">
                {{ message }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="green"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialogEdit = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="submitForm()"> CADASTRAR </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import SwitchButton from "../switch/SwitchProposalOrGuideline-comp.vue";
import DialogTable from "../dialogs/dialog-table-diretrizes.vue";
import AlertError2 from "../alerts/Error2-component.vue";

export default {
  data: () => ({
    code: "",
    dialog: false,
    dialogEdit: false,
    dialogDelete: false,
    snackbar: false,
    resultQuery: null,
    eixos: [],
    e1: 1,
    diretrizesIsActive: true,

    permissionLvl2: false,
    options: {},
    TotalResgister: 0,
    numberOfPages: 0,
    page: 1,
    items: [],
    message: [],
    message2: [],

    displayError2: {
      display2: false,
      message2: [],
    },

    // Cadastro
    content: null,
    eixo: null,
    id_proposal: null,

    formData: {
      content: null,
      axie_id: null,
      conference_id: null,
    },

    headers: [
      { text: "AÇÕES", value: "actions", sortable: false },

      { text: "CÓDIGO", value: "code" },
      {
        text: "CONTEÚDO DA DIRETRIZ",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "EIXO", value: "axie.title" },
    ],

    // Regras de validação
    mandatory: [(v) => !!v || "Campo obrigatório!"],

    cpfRules: [
      (v) => !!v || "O CPF é obrigatório",
      (v) => (v && v.length <= 14) || "Você excedeu o tamanho máximo.",
    ],

    nameRules: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 350) || "Você excedeu o tamanho máximo.",
    ],

    codeRules: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 6) || "Você excedeu o tamanho máximo!",
    ],

    nameLogradouro: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
    ],

    namesTrick: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
    ],

    emailRules: [
      (v) => !!v || "O campo é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "O e-mail não é válido.",
      (v) => (v && v.length <= 70) || "Você excedeu o tamanho máximo.",
    ],
  }),

  props: {
    resource: {
      type: Object,
    },
    origin: {
      type: String,
    },
  },

  components: {
    DialogTable,
    AlertError2,
    SwitchButton,
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getAll();
      },
    },
    deep: true,
  },

  created() {
    this.getAll();
    this.verification();
  },

  methods: {
    editItem(item) {
      this.loadSelects();
      this.dialogEdit = true;
      this.id_proposal = item.id;
      this.code = item.code;
      this.content = item.name;
      this.eixo = Number(item.axie_id);
    },

    submitForm() {
      if (!this.$refs.form.validate()) return;

      let complete = {
        name: this.content,
        axie_id: this.eixo,
        code: this.code,
      };

      if (this.origin == "state") {
        complete.state_id = this.$route.query.state_id;
      }
      if (this.origin == "regional_nucleus") {
        complete.regional_nucleus_health_id =
          this.$route.query.regional_nucleus_health_id;
      }
      if (this.origin == "institute") {
        complete.institute_id = this.$route.query.institute_id;
      }
      if (this.origin == "county") {
        complete.county_id = this.$route.query.county_id;
      }

      this.formData = complete;

      axios
        .put(`guideline/${this.id_proposal}`, this.formData)
        .then((res) => {
          this.displayError2.display2 = false;
          this.snackbar = true;
          this.message = res.data.message;
          this.$refs.form.reset();
          this.getAll();
          setTimeout(() => {
            this.dialogEdit = false;
          }, 2000);
        })
        .catch((err) => {
          this.$swal({
            icon: "error",
            title: "Erro ao lançar diretrizes",
            text: err.data.details,
          });
        });
    },

    loadSelects() {
      axios.get(`/conference/${this.$route.query.id}/axie`).then((response) => {
        this.eixos = response.data;
      });
    },

    getAll() {
      this.loading = true;

      const { page } = this.options;
      let pageNumber = page;
      let data = new Object();
      data.per_page = this.options.itemsPerPage;

      if (pageNumber == undefined) {
        pageNumber = 1;
      }
      if (data.per_page == undefined) {
        data.per_page = 10;
      }

      if (this.origin == "institute") {
        this.resultQuery = `conference/${this.$route.query.id}/guideline?institute_id=${this.$route.query.institute_id}`;
      }

      if (this.origin == "county") {
        this.resultQuery = `conference/${this.$route.query.id}/guideline?county_id=${this.$route.query.county_id}`;
      }

      if (this.origin == "regional_nucleus") {
        this.resultQuery = `conference/${this.$route.query.id}/guideline?regional_nucleus_health_id=${this.$route.query.regional_nucleus_health_id}`;
      }

      if (this.origin == "state") {
        this.resultQuery = `conference/${this.$route.query.id}/guideline?page=${pageNumber}&per_page=${data.per_page}&state_id=${this.$route.query.state_id}`;
      }

      axios.get(this.resultQuery).then((res) => {
        this.items = res.data.data;
        this.loading = false;
        this.TotalResgister = res.data.total;
        this.numberOfPages = res.data.last_page;
      });
    },

    deleteItem(item) {
      this.id_proposal = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      axios
        .delete(`guideline/${this.id_proposal}`)
        .then((res) => {
          this.getAll();
          this.snackbar = true;
          this.message2 = res.data.message;
          this.dialogDelete = false;
        })
        .catch((err) => {
          err;
          var error = "";
          Object.values(this.$store.state.errorMessage).map((value) => {
            error = value;
          });
          this.displayError2.message2 = error.toString();
          this.displayError2.display2 = true;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    verification() {
      axios.get(`conference/${this.$route.query.id}`).then((res) => {
        if (Number(res.data.hasguidelines)) {
          this.permissionLvl2 = true;
        } else {
          this.permissionLvl2 = false;
        }
      });

      if (
        Number(this.$store.state.finished_release_conference) &&
        Number(this.$store.state.restrict_release_conference)
      ) {
        this.permissionLvl2 =
          Number(this.$store.state.profile_id) == 1 ? true : false;
      } else {
        this.permissionLvl2 = true;
      }
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
<style scoped>
.delegate-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: right !important;
  margin: 0 20px;
}

.tab-2-dialog {
  height: 100%;
  width: 100%;
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.delegate-button div {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.areatext {
  width: 480px;
}
</style>