<template>
  <div>
    <v-tooltip bottom :color="switchColor" v-if="$route.query.state_id">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-switch
            v-model="switchRegister"
            color="success"
            value
            @click="swithRegister()"
          ></v-switch>
        </span>
      </template>
      <span>{{ switchStatus }}</span>
    </v-tooltip>

    <AlertSuccess v-model="displaySuccess" :resource="displaySuccess" />
  </div>
</template>

<script>
import axios from "@/axios";
import AlertSuccess from "../alerts/Success-component.vue";

export default {
  data() {
    return {
      valid: true,
      disabledd: false,
      authMe: null,
      switchStatus: "",
      switchColor: "",
      switchRegister: "",

      displayError: {
        display: false,
        message: [],
      },

      displaySuccess: {
        display: false,
        message: [],
      },
    };
  },

  props: {
    resource: {
      type: Object,
    },

    TypeOfSwitch: {
      type: String,
    },
  },

  components: {
    AlertSuccess,
  },

  created() {
    switch (this.TypeOfSwitch) {
      case "GUIDELINE_ELIGIBLE":
        this.verifyStatusRegisterEligible();
        break;
      case "PROPOSAL_ELIGIBLE":
        this.verifyStatusRegisterEligible();
        break;
      case "GUIDELINE_PLENARY":
        this.verifyStatusRegisterPlenary();
        break;
      case "PROPOSAL_PLENARY":
        this.verifyStatusRegisterPlenary();
        break;
    }
  },

  methods: {
    verifyStatusRegisterEligible() {
      if (this.resource.eligible_flag != null && this.resource.eligible_flag) {
        this.switchRegister = true;
        this.switchStatus = "Desativar";
        this.switchColor = "red";
        return;
      }
      this.switchRegister = false;
      this.switchStatus = "Ativar";
      this.switchColor = "success";
    },

    verifyStatusRegisterPlenary() {
      if (this.resource.plenary_flag != null && this.resource.plenary_flag) {
        this.switchRegister = true;
        this.switchStatus = "Desativar";
        this.switchColor = "red";
        return;
      }
      this.switchRegister = false;
      this.switchStatus = "Ativar";
      this.switchColor = "success";
    },

    swithRegister() {
      if (!this.switchRegister) {
        this.disableRegister();
        this.$emit("eventname", "");
        return;
      }

      this.enableRegister();
      this.$emit("eventname", "");
    },

    updateToElectGuideline() {
      axios
        .patch(`/guideline/${this.resource.id}/eligible`, {
          guideline: this.resource,
          state_id: this.$route.query.state_id,
        })
        .then(() => {
          this.$swal({
            icon: "success",
            showConfirmButton: false,
            title: "Diretriz eleita com sucesso",
            timer: 3000,
          });
        })
        .catch((err) => {
          this.switchRegister = false;
          this.$swal({
            icon: "error",
            title: "Erro ao lançar diretrizes eleitas",
            text: err.data.details,
          });
        });
    },
    updateToElectProposal() {
      axios
        .patch(`/proposal/${this.resource.id}/eligible`, {
          state_id: this.$route.query.state_id,
          conference_id: this.$route.query.id,
        })
        .then(() => {
          this.$swal({
            icon: "success",
            showConfirmButton: false,
            title: "Proposta eleita com sucesso",
            timer: 3000,
          });
        })
        .catch((err) => {
          this.switchRegister = false;
          this.$swal({
            icon: "error",
            title: "Erro ao lançar propostas eleitas",
            text: err.data.details,
          });
        });
    },

    updateToDisableEligibleGuideline() {
      axios.patch(`/guideline/${this.resource.id}/ineligible`).then(() => {
        this.$swal({
          icon: "success",
          title: "Diretriz atualizada com sucesso",
          showConfirmButton: false,
          timer: 3000,
        });
      });
    },
    updateToDisableEligibleProposal() {
      axios.patch(`/proposal/${this.resource.id}/ineligible`).then(() => {
        this.$swal({
          icon: "success",
          title: "Proposta atualizada com sucesso",
          showConfirmButton: false,
          timer: 3000,
        });
      });
    },

    updateToEnablePlenaryProposal() {
      axios
        .patch(`/proposal/${this.resource.id}/enable-plenary`, {
          state_id: this.$route.query.state_id,
          conference_id: this.$route.query.id,
        })
        .then(() => {
          this.$swal({
            icon: "success",
            showConfirmButton: false,
            title: "Proposta atualizada com sucesso",
            timer: 3000,
          });
        });
    },

    updateToEnablePlenaryGuideline() {
      axios
        .patch(`/guideline/${this.resource.id}/enable-plenary`, {
          state_id: this.$route.query.state_id,
          conference_id: this.$route.query.id,
        })
        .then(() => {
          this.$swal({
            icon: "success",
            showConfirmButton: false,
            title: "Diretriz atualizada com sucesso",
            timer: 3000,
          });
        });
    },

    updateToDisablePlenaryProposal() {
      axios.patch(`/proposal/${this.resource.id}/disable-plenary`).then(() => {
        this.$swal({
          icon: "success",
          title: "Proposta atualizada com sucesso",
          showConfirmButton: false,
          timer: 3000,
        });
      });
    },

    updateToDisablePlenaryGuideline() {
      axios.patch(`/guideline/${this.resource.id}/disable-plenary`).then(() => {
        this.$swal({
          icon: "success",
          title: "Diretriz atualizada com sucesso",
          showConfirmButton: false,
          timer: 3000,
        });
      });
    },

    enableRegister() {
      switch (this.TypeOfSwitch) {
        case "GUIDELINE_ELIGIBLE":
          this.updateToElectGuideline();
          break;
        case "PROPOSAL_ELIGIBLE":
          this.updateToElectProposal();
          break;
        case "GUIDELINE_PLENARY":
          this.updateToEnablePlenaryGuideline();
          break;
        case "PROPOSAL_PLENARY":
          this.updateToEnablePlenaryProposal();
          break;
      }
    },

    disableRegister() {
      switch (this.TypeOfSwitch) {
        case "GUIDELINE_ELIGIBLE":
          this.updateToDisableEligibleGuideline();
          break;
        case "PROPOSAL_ELIGIBLE":
          this.updateToDisableEligibleProposal();
          break;
        case "GUIDELINE_PLENARY":
          this.updateToDisablePlenaryGuideline();
          break;
        case "PROPOSAL_PLENARY":
          this.updateToDisablePlenaryProposal();
          break;
      }
    },
  },
};
</script>

<style scoped>
.space {
  height: 10px;
}

.iconeEditar:hover {
  color: #54c6eb;
}

.iconeDeletar:hover {
  color: #ad2831;
}

.card {
  padding: 20px;
}

.banner-edit {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgba(95, 195, 228, 1) 0%,
    rgba(93, 148, 229, 1) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-delete {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgb(235, 30, 57) 0%,
    rgb(248, 96, 36) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-edit > h4,
.banner-delete > h4 {
  color: #ffffff;
  padding: 10px;
}

.textInfo {
  padding: 15px;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 8px;
  text-align: center;
}

.textDelete {
  font-family: "Poppins";
  font-weight: 400;

  font-size: 14px;
}

.userName {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.alertText {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;

  margin-top: 15px;

  color: #f44336;
}

.cardFooter {
  display: flex;
  justify-content: center;

  margin-top: 20px;
}

.cardFooter {
  padding: 0 !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}
</style>
