<template>
  <div class="municipality">
    <v-card class="table-dashboard">
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        sort-by="calories"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <div class="delegate-button">
                  <v-btn
                    v-show="permissionLvl2"
                    color="green"
                    v-if="
                      $store.state.profile_id != 2 &&
                      $store.state.profile_id != 21 &&
                      $store.state.profile_id != 41
                    "
                    id="btn-space"
                    dark
                    @click="finish()"
                  >
                    FINALIZAR
                  </v-btn>
                  <div>
                    <DialogTableAnexos
                      :origin="origin"
                      v-show="permissionLvl2"
                      :resource="resource"
                      @refreshGrid="getAll"
                      v-bind="attrs"
                      v-on="on"
                    />
                    <v-btn color="green" dark @click="getAllSelects()">
                      atualizar
                    </v-btn>
                  </div>
                </div>
              </template>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 text-center-center"
                  >Tem certeza que deseja deletar este item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogDelete = false"
                    >cancelar</v-btn
                  >
                  <v-btn color="red darken-1" text @click="deleteItemConfirm"
                    >Deletar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogFinish" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 text-center-center"
                  >Tem certeza que deseja finalizar ?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogFinish = false"
                    >cancelar</v-btn
                  >
                  <v-btn color="red darken-1" text @click="finishConfirme"
                    >Finalizar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template
          v-slot:item.actions="{ item }"
          v-if="$store.state.profile_id != 2"
        >
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogEdit" max-width="780">
      <AlertError2
        class="mb-5"
        v-model="displayError2"
        :resource="displayError2"
      />
      <v-card>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 1" step="1">
              Cadastro
            </v-stepper-step>
            <v-divider> </v-divider>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12 px-5 py-5" height="280px">
                <v-form ref="form" lazy-validation class="tab-1-dialog">
                  <v-textarea
                    v-model="observation"
                    :rules="mandatory"
                    solo
                    class="textareamain"
                    name="input-7-4"
                    label="Observação"
                  ></v-textarea>
                </v-form>
              </v-card>
              <v-snackbar v-model="snackbar">
                {{ message }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="green"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
              <div class="bnt-bottom-conf">
                <v-btn text @click="dialogEdit = false"> Cancelar </v-btn>
                <v-btn color="primary" @click="submitForm()"> FINALIZAR </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import DialogTableAnexos from "../dialogs/dialog-table-note.vue";
import AlertError2 from "../alerts/Error2-component.vue";

export default {
  data: () => ({
    dialog: false,
    loading: true,
    dialogFinish: false,
    get_conference: null,
    snackbar: false,
    urlResults: null,

    dialogEdit: false,
    e1: 1,
    message: [],
    message2: [],
    eixos: null,
    diretrizesAll: null,
    proposalAll: null,
    permissionLvl2: false,
    isDiretrizes: null,

    active: true,

    id_note: null,
    id_delete: null,

    displayError2: {
      display2: false,
      message2: [],
    },

    formFinish: {
      finished_release_conference: null,
    },

    // Cadastro
    observation: null,

    formData: {
      observation: null,
      conference_id: null,
      county_id: null,
    },

    items: [],
    dialogDelete: false,
    headers: [
      { text: "AÇÕES", value: "actions", sortable: false },
      {
        text: "OBSERVAÇÃO",
        align: "start",
        sortable: false,
        value: "observation",
      },
    ],
  }),

  props: {
    resource: {
      type: Object,
    },
    origin: {
      type: String,
    },
  },

  components: {
    DialogTableAnexos,
    AlertError2,
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getAllSelects();
  },

  methods: {
    getAllSelects() {
      this.getAll();
      this.verification();
    },
    editItem(item) {
      this.dialogEdit = true;
      this.id_note = item.id;
      this.observation = item.observation;
    },

    submitForm() {
      if (!this.$refs.form.validate()) return;
      this.formData.observation = this.observation;
      this.formData.conference_id = this.$route.query.id;

      if (this.origin == "institute") {
        this.formData.institute_id = this.$route.query.institute_id;
      } else if (this.origin == "county") {
        this.formData.county_id = this.$route.query.county_id;
      }

      axios
        .put(`observation/${this.id_note}`, this.formData)
        .then((res) => {
          this.getAll();
          this.displayError2.display2 = false;
          this.snackbar = true;
          this.message = res.data.message;
          this.$refs.form.reset();
          setTimeout(() => {
            this.e1 = 1;
            this.dialogEdit = false;
          }, 2000);
        })
        .catch((err) => {
          err;
          var error = null;
          Object.values(this.$store.state.errorMessage).map((value) => {
            error = value;
          });
          this.displayError2.message2 = error.toString();
          this.snackbar = true;
          this.message = error.toString();
          this.displayError2.display2 = true;
        });
    },

    getAll() {
      this.loading = true;

      if (this.origin == "institute") {
        this.resultQuery = `institute_id=${this.$route.query.institute_id}`;
      }

      if (this.origin == "county") {
        this.resultQuery = `county_id=${this.$route.query.county_id}`;
      }

      if (this.origin == "regional_nucleus") {
        this.resultQuery = `regional_nucleus_health_id=${this.$route.query.regional_nucleus_health_id}`;
      }

      if (this.origin == "state") {
        this.resultQuery = `state_id=${this.$route.query.state_id}`;
      }

      axios
        .get(
          `conference/${this.$route.query.id}/observation?${this.resultQuery}`
        )
        .then((res) => {
          this.loading = false;
          console.log(res.data.data);
          this.items = res.data.data;
        });

      axios.get(`/conference/${this.$route.query.id}/axie`).then((response) => {
        this.eixos = response.data;
      });

      axios
        .get(`conference/${this.$route.query.id}/proposal?${this.resultQuery}`)
        .then((res) => {
          this.proposalAll = res.data.data;
          this.loading = false;
        });

      axios.get(`conference/${this.$route.query.id}`).then((res) => {
        this.get_conference = res.data.hasguidelines;
      });

      this.verification();
    },

    disabledButton() {
      if (Number(this.get_conference)) {
        if (
          this.diretrizesAll === this.eixos.length &&
          this.proposalAll.length
        ) {
          this.active = false;
        }
      }
    },

    deleteItem(item) {
      this.id_note = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      axios
        .delete(`observation/${this.id_note}`)
        .then((res) => {
          this.getAll();
          this.snackbar = true;
          this.message = res.data.message;
          this.dialogDelete = false;
        })
        .catch((err) => {
          err;
          var error = "";
          Object.values(this.$store.state.errorMessage).map((value) => {
            error = value;
          });
          this.displayError2.message2 = error.toString();
          this.displayError2.display2 = true;
        });
    },

    verification() {
      if (this.origin == "institute") {
        this.resultQuery = `conference/${this.$route.query.id}/guideline?institute_id=${this.$route.query.institute_id}`;
      }

      if (this.origin == "county") {
        this.resultQuery = `conference/${this.$route.query.id}/guideline?county_id=${this.$route.query.county_id}`;
      }

      if (this.origin == "regional_nucleus") {
        this.resultQuery = `conference/${this.$route.query.id}/guideline?regional_nucleus_health_id=${this.$route.query.regional_nucleus_health_id}`;
      }

      if (this.origin == "state") {
        this.resultQuery = `conference/${this.$route.query.id}/guideline?state_id=${this.$route.query.state_id}`;
      }

      axios.get(this.resultQuery).then((res) => {
        this.diretrizesAll = res.data.total;
      });

      if (
        Number(this.$store.state.finished_release_conference) &&
        Number(this.$store.state.restrict_release_conference)
      ) {
        this.permissionLvl2 =
          Number(this.$store.state.profile_id) == 1 ? true : false;
      } else {
        this.permissionLvl2 = true;
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogFinish = false;
    },

    finish() {
      this.dialogFinish = true;
    },

    finishConfirme() {
      let obj = new Object();
      obj.conference_id = this.$route.query.id;
      if (this.origin == "institute") {
        obj.institute_id = this.$route.query.institute_id;
      } else if (this.origin == "county") {
        obj.county_id = this.$route.query.county_id;
      } else if (this.origin == "regional_nucleus") {
        obj.regional_nucleus_health_id =
          this.$route.query.regional_nucleus_health_id;
      } else if (this.origin == "state") {
        obj.state_id = this.$route.query.state_id;
      }

      axios
        .post("conference/finish", obj)
        .then((res) => {
          this.snackbar = true;
          this.message = res.data.message;

          setTimeout(() => {
            this.$router.push({
              path: "/dashboard",
            });
            this.dialogFinish = false;
          }, 2000);
        })
        .catch((err) => {
          this.snackbar = true;
          this.message = err.data.details;
          return;
        });
    },
  },
};
</script>
<style scoped>
.delegate-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: right !important;
  margin: 0 20px;
  gap: 20px;
}

.delegate-button div {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

textareamain {
  width: 100%;
  height: 100%;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

#btn-space {
  margin-right: 10px;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.text-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.banner {
  background: #b3ffab;
  background: -webkit-linear-gradient(to right, #27ae60, #1abc9c);
  background: linear-gradient(to right, #27ae60, #1abc9c);

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>